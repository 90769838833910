import { merge } from 'lodash';
import type { RegisterOptions } from 'react-hook-form/dist/types/validator';

import i18n from '@data/other/i18n';

export const isEmailPattern = /^\w+(['+.-]\w+)*@\w+([.-]\w+)*\.\w+([.-]\w+)*$/;
export const isCreditCardNumberPattern =
  /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
export const isCreditCardExpirationPattern =
  /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;

export const emailValidate = (value: string) => {
  if (!isEmailPattern.test(value)) {
    return i18n.t('validation/email');
  }
  return undefined;
};

export const creditCardNumberValidate = (value: string) => {
  const cardNumber = value.replace(/[^0-9]/g, '');
  if (!isCreditCardNumberPattern.test(cardNumber)) {
    return i18n.t('validation/credit-card-number');
  }
  return undefined;
};

export const cvvNumberValidate = (value: string) => {
  if (value.length !== 3 && value.length !== 4) {
    return i18n.t('validation/cvc');
  }
  return undefined;
};

export const creditCardExpirationValidate = (value: string) => {
  if (!isCreditCardExpirationPattern.test(value)) {
    return i18n.t('validation/credit-card-expiration');
  }

  const currentDate = new Date();
  const monthEntered = parseInt(value.split('/')[0]);
  const yearEntered = parseInt('20' + value.split('/')[1]);

  if (monthEntered < 1 || monthEntered > 12) {
    return i18n.t('validation/credit-card-expiration');
  }

  if (yearEntered < currentDate.getFullYear()) {
    return i18n.t('validation/credit-card-expiration');
  }

  return undefined;
};

export const createValidation = <T extends Record<string, any>>(
  validation: FormValidation<T>,
  additionalRules?: FormValidation<T>
): FormValidation<T> => merge({}, validation, additionalRules);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValidation<T extends Record<string, any>> = Partial<
  Record<keyof T, RegisterOptions>
>;
