import { AccommodationData } from '@data/types/AccommodationData';
import { AddonData } from '@data/types/AddonData';
import { Currency, Language } from '@data/types/common';
import { DateString } from '@data/api/types/native';
import {
  RawBookingRoom,
  RawReservation,
  ReservationStatus,
} from '@data/api/types/reservation';

import { PAYMENT_METHOD } from '../types/Payment';

import ChargeType from './ChargeType';
import { DetailedTaxesAndFees } from './DetailedTaxesAndFees';
import Guest from './Guest';

export type ReservationCharges = {
  accommodations: number;
  addons: number;
  balanceDue: number;
  currencyFrom: Currency;
  currencyRate: number;
  currencyTo: Currency;
  deposit: number;
  detailedTaxesAndFees: DetailedTaxesAndFees;
  grandTotal: number;
  netTotal: number;
  paidValue: number;
  taxesAndFees: number;
};

export default class Reservation {
  accommodations: AccommodationData[] = [];
  addons: AddonData[] = [];
  bookingRooms!: RawBookingRoom[];
  charges!: ReservationCharges;
  checkin!: DateString;
  checkout!: DateString;
  date!: Date;
  error?: string;
  formattedRooms: Record<string, AccommodationData> = {};
  guests: Guest[] = [];
  lang?: Language;
  nights!: number;
  paymentMethod!: PAYMENT_METHOD;
  propertyCode!: string;
  propertyId!: number;
  reservationId!: string;
  reservationStatus!: ReservationStatus;
  status!: boolean;

  constructor(serverData?: {
    data?: RawReservation;
    message?: string;
    success: boolean;
  }) {
    if (!serverData || !serverData.success || !serverData.data) {
      this.status = false;
      this.error = serverData?.message || 'Unknow error';

      return;
    }

    const { data: reservation } = serverData;

    this.bookingRooms = reservation.mail_info.booking_rooms || [];
    this.status = true;
    this.propertyId = parseInt(reservation.property_id);
    this.propertyCode = reservation.property_code;
    this.date = new Date(reservation.created_at);
    this.paymentMethod = reservation.payment_method;
    this.reservationStatus = reservation.mail_info.status;
    this.reservationId = reservation.identifier;

    this.checkin = reservation.mail_info.checkin_date;
    this.checkout = reservation.mail_info.checkout_date;
    this.nights = parseInt(reservation.mail_info.nights);

    this.lang = reservation.mail_info.lang as Language;

    this.charges = {
      accommodations: 0,
      addons: 0,
      balanceDue: 0,
      currencyFrom:
        reservation.mail_info.currency_from.toLowerCase() as Currency,
      currencyRate: parseFloat(reservation.mail_info.currency_rate || '1'),
      currencyTo: reservation.mail_info.currency_to.toLowerCase() as Currency,
      deposit: 0,
      detailedTaxesAndFees: {
        data: [],
        total: 0,
      },
      grandTotal: 0,
      netTotal: 0,
      paidValue: 0,
      taxesAndFees: 0,
    };

    this.charges.paidValue = reservation.paid_value;
    this.charges.deposit = parseFloat(reservation.deposit);
    this.charges.balanceDue = reservation.mail_info.balance_due;

    const guest = new Guest();
    guest.firstName = reservation.mail_info.first_name;
    guest.lastName = reservation.mail_info.last_name;
    guest.email = reservation.mail_info.email;
    guest.phone = reservation.mail_info.phone;
    guest.country = reservation.mail_info.country;
    guest.city = reservation.mail_info.city;
    guest.state = reservation.mail_info.state;

    this.guests.push(guest);

    this.accommodations = [];
    const rateIdByRoomId = {};
    const roomTypeNameByRoomId = {};

    this.charges.accommodations = reservation.mail_info.rooms_total || 0;

    for (const room of reservation.mail_info.booking_rooms) {
      // @TECHDEBT: Since package_id does not exist in RawBookingInfo interface, this condition always resolves to false.
      // So accommodations are not grouped after the execution of the Reservation constructor, commenting this code
      // to keep current behavior. Review if we need the Reservation model to have the accommodations grouped.

      // const addedAccommodation = this.accommodations.find(
      //   (x) =>
      //     x.roomId === room.room_type_id &&
      //     x.packageId === room.package_id &&
      //     x.kids === room.kids &&
      //     x.adults === room.adults
      // );

      // if (addedAccommodation) {
      //   addedAccommodation.amount += 1;
      //   continue;
      // }

      const item: AccommodationData = {
        adults: parseInt(room.adults),
        amount: 1,
        bookedId: [room.id],
        kids: parseInt(room.kids),
        name: room.room_type_name,
        packageId: room.package,
        packageName: room.package_name || 'Standard Rate',
        picture: room.room_type_photos?.[0]?.path || '',
        price: parseFloat(room.room_total),
        rateId: room.rate_id,
        roomId: room.room_type_id,
      };

      rateIdByRoomId[room.id] = room.rate_id;
      roomTypeNameByRoomId[room.id] = room.room_type_name;

      this.accommodations.push(item);
    }

    this.formattedRooms = this.accommodations.reduce(
      (roomDict, room: AccommodationData) => {
        const identifier = `${room.roomId}-${room.packageId}`;

        if (roomDict[identifier]) {
          roomDict[identifier].quantity =
            roomDict[identifier].quantity + room.amount;
        } else {
          roomDict[identifier] = {
            id: identifier,
            name: room.name,
            price: room.price,
            quantity: room.amount,
          };
        }

        return roomDict;
      },
      {}
    );

    this.addons = [];
    this.charges.addons = reservation.mail_info.addons.totalNetPrice;

    for (const addon of reservation.sold_addons) {
      const accommodation = this.accommodations.find(({ bookedId }) =>
        bookedId.includes(addon.bookingRoomId.toString())
      );

      if (!accommodation) {
        continue;
      }

      const item: AddonData = {
        accommodationAdults: accommodation.adults,
        accommodationBookedId: addon.bookingRoomId.toString(),
        accommodationId: accommodation.roomId,
        accommodationKids: accommodation.kids,
        accommodationName: accommodation.name,
        accommodationPackageId: accommodation.packageId,
        addonId: addon.addonId.toString(),
        amount: addon.quantity,
        bookedId: addon.ids.map((x) => x.toString()),
        chargeType: (addon?.chargeType.replaceAll('_', '-') ||
          'per-reservation') as ChargeType,
        name: addon.name,
        picture: addon.addon_photos?.[0]?.path || '',
        price: addon.totalPriceWithInclusive,
        rateId: addon.addonId.toString(),
      };

      this.addons.push(item);
    }

    const taxesAndFees = {
      ...reservation.sub_fees_grouped_by_name,
      ...reservation.sub_taxes_grouped_by_name,
    };

    for (const key in taxesAndFees) {
      const taxOrFee = taxesAndFees[key];

      // When we receive a float value, it's comming as string, so we have to parse to have sure it will be a number
      taxOrFee.credit =
        typeof taxOrFee.credit === 'string'
          ? parseFloat(taxOrFee.credit)
          : taxOrFee.credit;

      this.charges.detailedTaxesAndFees.data.push({
        ...taxOrFee,
        credit: (taxOrFee.credit as number) * (this.charges.currencyRate || 1),
        id: taxOrFee.tax_id,
      });

      this.charges.detailedTaxesAndFees.total += taxOrFee.credit;

      this.charges.taxesAndFees += taxOrFee.credit;
    }

    this.charges.netTotal =
      this.charges.accommodations +
      this.charges.addons +
      this.charges.taxesAndFees;
    this.charges.grandTotal = reservation.total;
  }
}
