import Linkify from 'react-linkify';

import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useColors } from '@view/hooks/useColors';

type TextProps = Exclude<
  ChakraTextProps,
  'color' | 'colorScheme' | 'noOfLines'
> & {
  color?: string;
  disableLink?: boolean;
  html?: string | TrustedHTML;
  // Safari needs to have the maxHeight defined in order for the line-clamp rule to work properly.
  lineClamp?: {
    maxHeight: `${number}px`;
    noOfLines: number;
  };
  testid?: string;
};

const HTMLContainer = styled(ChakraText)`
  * {
    text-align: initial;
  }

  img {
    object-fit: contain;
  }

  ol, ul {
    padding-left: 20px;
  }
`;

export const Text = ({
  children,
  disableLink = false,
  html,
  lineClamp,
  testid,
  color,
  ...textProps
}: TextProps) => {
  const { getThemeColor } = useColors();

  const _color = getThemeColor(color);

  return html ? (
    <HTMLContainer
      className="cb-text"
      color={_color}
      dangerouslySetInnerHTML={{ __html: html }}
      data-testid={testid}
      maxHeight={lineClamp?.maxHeight}
      noOfLines={lineClamp?.noOfLines}
      {...textProps}
    ></HTMLContainer>
  ) : (
    <ChakraText
      className="cb-text"
      data-testid={testid}
      maxHeight={lineClamp?.maxHeight}
      noOfLines={lineClamp?.noOfLines}
      {...textProps}
      color={_color}
    >
      {disableLink ? children : <Linkify>{children}</Linkify>}
    </ChakraText>
  );
};
