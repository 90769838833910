import type { ComponentType, FunctionComponent } from 'react';
import { useContext } from 'react';

import type { IWrappedComponent } from 'mobx-react';
import { inject, MobXProviderContext } from 'mobx-react';

import type { Store, StoreProps } from '@data/stores/Store';

import type { Subtract } from './utility';

export const withStores =
  <TStoreProps extends keyof StoreProps>(...stores: TStoreProps[]) =>
  <TProps extends Pick<StoreProps, TStoreProps>>(
    component: ComponentType<TProps>
  ) =>
    inject(...stores)(component) as unknown as FunctionComponent<
      Subtract<TProps, Pick<StoreProps, TStoreProps>> &
        Partial<Pick<StoreProps, TStoreProps>>
    > &
      IWrappedComponent<TProps>;

export type StoreRecord = Record<'store', Store>;

export const withStore = withStores('store');

export const useStore = () =>
  (useContext(MobXProviderContext) as StoreRecord).store;
