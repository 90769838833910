import { parseRawAddonListKey } from '@data/api/helpers';
import { RawAddon } from '@data/api/types/addon';
import { RawAddonList } from '@data/api/types/addon-list';

import Addon from './Addon';

export default class AddonList {
  items: Addon[] = [];

  constructor(serverData?: {
    data: RawAddonList;
    items?: RawAddon[];
    success?: boolean;
  }) {
    if (!serverData || !serverData.success || !serverData?.data) {
      return;
    }

    const { data, items } = serverData;

    const keys = Object.keys(data);

    for (const key of keys) {
      const { adults, kids, roomId, packageId } = parseRawAddonListKey(key);

      const addonsId = Object.keys(data[key]);

      this.items.push(
        ...addonsId.map(
          (addonId) =>
            new Addon({
              ...data[key][addonId],
              adults,
              kids,
              packageId,
              roomId,
            })
        )
      );
    }

    if (items) {
      this.items.push(...items.map((item) => new Addon(item)));
    }

    this.sortItems();
  }

  private sortItems = () =>
    this.items.sort((a, b) =>
      a.order < 0 ? 1 : b.order < 0 ? -1 : a.order - b.order
    );
}
