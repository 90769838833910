import { action, makeObservable, observable } from 'mobx';

import TimeUtils from '@business/utils/time';

import { IPersistentStore } from '../IPersistentStore';
import { persistentPropertyStorage } from '../PersistentPropertyStorage';

export type SearchPanelValue = {
  adults?: number;
  checkin?: Date;
  checkout?: Date;
  code?: string;
  kids?: number;
  ratePlans?: string;
  roomIds?: string;
  roomType?: string;
};

export default class SearchPanel implements IPersistentStore {
  @observable stagedValue: SearchPanelValue = {};
  @observable value: SearchPanelValue = {};

  constructor() {
    makeObservable(this);
  }

  get storageKey(): string {
    return 'searchPanel';
  }

  @action.bound clear() {
    this.value = {};
  }

  @action.bound clearStagedValue() {
    this.stagedValue = {};
  }

  @action.bound initializeComponent(value: SearchPanelValue) {
    this.value = value;
    this.stagedValue = value;
  }

  load(): void {
    const loadedData = persistentPropertyStorage.getItem(this.storageKey);
    if (!loadedData) return;

    const data = JSON.parse(loadedData);
    this.value = {
      ...data.value,
      checkin: this.parseDate(data.value.checkin),
      checkout: this.parseDate(data.value.checkout),
    };

    this.stagedValue = {
      ...data.stagedValue,
      checkin: this.parseDate(data.stagedValue.checkin),
      checkout: this.parseDate(data.stagedValue.checkout),
    };
  }

  parseDate(date: string | undefined): Date | undefined {
    return date ? TimeUtils.fromIso(date, { startOfDay: true }) : undefined;
  }

  save(): void {
    const data = {
      stagedValue: {
        ...this.stagedValue,
        checkin: this.stagedValue.checkin
          ? TimeUtils.toDateString(this.stagedValue.checkin)
          : undefined,
        checkout: this.stagedValue.checkout
          ? TimeUtils.toDateString(this.stagedValue.checkout)
          : undefined,
      },
      value: {
        ...this.value,
        checkin: this.value.checkin
          ? TimeUtils.toDateString(this.value.checkin)
          : undefined,
        checkout: this.value.checkout
          ? TimeUtils.toDateString(this.value.checkout)
          : undefined,
      },
    };

    persistentPropertyStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  @action.bound set(value: SearchPanelValue) {
    this.value = { ...this.value, ...value };
    this.stagedValue = { ...this.stagedValue, ...value };
  }

  @action.bound setStagedValue(value: SearchPanelValue) {
    this.stagedValue = { ...this.stagedValue, ...value };
  }
}
