export default class Image {
  src!: string;
  thumb!: string;
  alt?: string;

  constructor(data?: any) {
    if (!data) return;
    if (data?.constructor === String) {
      this.src = data as string;
      this.thumb = data as string;
      this.alt = data as string;
      return;
    }

    if (!this.src) {
      this.src = data.src || data.full_path || data.path;
    }

    if (!this.thumb) {
      this.thumb = data.thumb || data.thumb_path || this.src;
    }

    if (!this.alt) {
      this.alt = data.alt || '';
    }
  }
}
