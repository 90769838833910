import { Spinner as Spin } from '@chakra-ui/react';

interface LoaderProps {
  className?: string;
  testid?: string;
}

const Loader = ({ className, testid }: LoaderProps) => (
  <Spin className={className} data-testid={testid || 'loader'} />
);

export default Loader;
