import {
  GoogleTroasScriptData,
  RawScriptData,
  RetargetingScriptData,
} from '@data/api/types/amplify/script-data';

export default class ScriptData {
  googleTroas?: GoogleTroasScriptData;
  retargeting?: RetargetingScriptData;

  constructor(data?: RawScriptData) {
    if (data?.success) {
      this.retargeting = data?.data?.retargeting;
      this.googleTroas = data?.data?.metasearchAds?.googleTroas;
    }
  }
}
