type ChargeType =
  | 'per-reservation'
  | 'per-night'
  | 'per-accommodation'
  | 'per-accommodation-per-night'
  | 'per-guest'
  | 'per-guest-per-night'
  | 'quantity';

export default ChargeType;

// Addons with these charge types should be added to each room in a ReservationItem
export const VARIABLE_CHARGE_TYPES: ChargeType[] = [
  'per-accommodation',
  'per-accommodation-per-night',
  'per-guest',
  'per-guest-per-night',
];
