import { BooleanString } from '@data/api/types/native';
import { FormValidation, emailValidate } from '@data/other/validation';

export default class Guest {
  /* minimun fields to complete reservation */
  firstName!: string;
  lastName!: string;
  email!: string;

  gender?: 'M' | 'F' | 'N/A';
  phone?: string;

  isSameAddressAsMain?: string;
  country?: string;
  state?: string;
  zipCode?: string;
  city?: string;
  address1?: string;
  address2?: string;

  estimatedArrivalTime?: number;
  taxIdNumber?: string;
  companyName?: string;
  companyTaxIdNumber?: string;

  isOptIn?: boolean;

  constructor(data: Dict[] | Dict = []) {
    if (!data) return;
    let guestFormData = data;
    if (Array.isArray(guestFormData)) {
      guestFormData = {};
      data.forEach((item) => {
        guestFormData[item.name] = item.value;
      });
    }

    this.firstName = guestFormData.first_name || '';
    this.lastName = guestFormData.last_name || '';
    this.email = guestFormData.email || '';
    this.phone = guestFormData.phone || '';
    this.isOptIn = guestFormData.is_opt_in || undefined;
  }

  public static validationModel: FormValidation<any> = {
    address1: { maxLength: 95 },
    address2: { maxLength: 95 },
    city: { maxLength: 35 },
    companyName: { maxLength: 45 },
    companyTaxIdNumber: { maxLength: 45 },
    country: { required: true },
    email: { maxLength: 60, required: true, validate: emailValidate },
    firstName: { maxLength: 45, required: true },
    gender: { required: true },
    lastName: { maxLength: 45, required: true },
    phone: { maxLength: 40, required: true },
    taxIdNumber: { maxLength: 45 },
    zipCode: { maxLength: 12 },
  };
}
