import TimeUtils from '@business/utils/time';
import CartTotals from '@data/models/CartTotals';
import { VARIABLE_CHARGE_TYPES } from '@data/models/ChargeType';
import { AccommodationData } from '@data/types/AccommodationData';
import { AddonData } from '@data/types/AddonData';
import { Currency } from '@data/types/common';
import ReservationItem from '@data/models/ReservationItem';
import { isAllotmentBlockCode } from '@business/utils/promocodes';
import Store from '@data/stores/Store';

import { apiCall } from '../axios';

type RateData = {
  addons?: {
    addon_id: string;
    quantity: number;
  }[];
  adults: number;
  kids: number;
  rateId: string;
};

type CalculateTotalsOptions = {
  accommodations: AccommodationData[];
  addons?: AddonData[];
  checkIn: Date;
  checkOut: Date;
  code?: string;
  currency?: Currency;
  propertyId: number;
};

type UpdateTotalsOptions = {
  addons: AddonData[];
  cartItems: ReservationItem[];
  data_res: string;
  propertyId: number;
};

export class CartService {
  static async calculate({
    accommodations,
    addons = [],
    checkIn,
    checkOut,
    code,
    currency,
    propertyId,
  }: CalculateTotalsOptions): Promise<CartTotals> {
    // loop throw all the accommodations and create an object to send to the api
    const rates: RateData[] = [];

    for (let i = 0; i < accommodations.length; i++) {
      const accommodation = accommodations[i];

      const accommodationAddons = this.findAddonsOfAccommodation(
        accommodation,
        addons
      );

      for (let j = 0; j < accommodation.amount; j++) {
        const rate: RateData = {
          addons: [],
          adults: accommodation.adults,
          kids: accommodation.kids,
          rateId: accommodation.rateId,
        };

        accommodationAddons.forEach((addon) => {
          // if the addon is not variable, we add it to the first room only
          if (
            addon.chargeType &&
            !VARIABLE_CHARGE_TYPES.includes(addon.chargeType) &&
            j > 0
          ) {
            return;
          }

          rate.addons?.push({
            addon_id: addon.addonId,
            quantity: addon.amount,
          });
        });

        rates.push(rate);
      }
    }

    const body: Dict = {};
    body.lang = 'en';
    body['data[checkIn]'] = TimeUtils.format(checkIn);
    body['data[checkOut]'] = TimeUtils.format(checkOut);
    body['data[currency]'] = currency?.toUpperCase() || 'USD';
    body['data[rates]'] = JSON.stringify(rates);
    body['property_id'] = propertyId;
    if (code && isAllotmentBlockCode(code)) {
      body['data[allotmentBlockCode]'] = code;
    } else if (code) {
      body['data[promo]'] = code;
    }

    const totals: CartTotals = await apiCall(CartTotals)(
      'post',
      '/calculateTotals',
      body
    );

    Store.session.setCartToken(totals.cart_token);
    return totals;
  }

  private static findAddonsOfAccommodation(
    accommodation: AccommodationData,
    addons: AddonData[]
  ): AddonData[] {
    const accommodationAddons = addons.filter(
      (addon) =>
        addon.accommodationId === accommodation.roomId &&
        addon.accommodationPackageId === accommodation.packageId &&
        addon.accommodationAdults === accommodation.adults &&
        addon.accommodationKids === accommodation.kids
    );

    return accommodationAddons;
  }

  public static async updateTotals({
    addons,
    propertyId,
    cartItems,
    data_res,
  }: UpdateTotalsOptions): Promise<CartTotals> {
    const body: Dict = {};
    body.lang = 'en';
    let index = 0;
    addons.forEach((addon, i) => {
      const accommodation = [...cartItems].find((x) =>
        x.model?.item.bookedId.includes(addon.accommodationBookedId)
      );

      const bookedId = accommodation?.model?.item.bookedId || [];
      for (let i = 0; i < bookedId.length; i++) {
        body[`addons[${index}][addon_id]`] = addon.addonId;
        body[`addons[${index}][quantity]`] = addon.amount;
        body[`addons[${index}][booking_room_id]`] = bookedId[i];
        index++;
      }
    });
    body['property_id'] = propertyId;

    return await apiCall(CartTotals)(
      'post',
      `/update_total?data_res=${data_res}`,
      body
    );
  }
}
