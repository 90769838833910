import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { Property } from '@data/models';
import { useStore } from '@data/other/withStore';
import { Routes as AppRoutes } from '@view/routes';

export const useApplicationFlow = () => {
  const { property } = useStore();
  const { pathname } = useLocation();

  const displayAddons = useMemo(
    (): Property['settings']['displayAddons'] =>
      property.model?.settings.displayAddons || 'preCheckout',
    [property.model?.settings?.displayAddons]
  );

  const selectAddonsBeforePayment = useMemo(
    () => displayAddons === 'preCheckout',
    [displayAddons]
  );

  const selectAdonsOnConfirmationPage = useMemo(
    () => displayAddons === 'postCheckout',
    [displayAddons]
  );

  const pageOrder = useMemo(() => {
    if (displayAddons === 'preCheckout') {
      return [
        AppRoutes.Accommodations,
        AppRoutes.Addons,
        AppRoutes.AddGuest,
        AppRoutes.Payment,
        AppRoutes.ReservationConfirmed,
      ];
    }

    return [
      AppRoutes.Accommodations,
      AppRoutes.AddGuest,
      AppRoutes.Payment,
      AppRoutes.ReservationConfirmed,
    ];
  }, [displayAddons]);

  const currentPage = useMemo(() => {
    const endPath = pathname.split('/').slice(-1)[0];

    return (
      pageOrder.find((page) => page === endPath) || AppRoutes.Accommodations
    );
  }, [pathname, pageOrder]);

  const nextPage = useMemo(() => {
    const index = pageOrder.indexOf(currentPage);

    if (index < pageOrder.length - 1) {
      return pageOrder[index + 1];
    }

    return AppRoutes.Accommodations;
  }, [currentPage, pageOrder]);

  const previousPage = useMemo(() => {
    const index = pageOrder.indexOf(currentPage);

    if (index > 0) {
      return pageOrder[index - 1];
    }
    return AppRoutes.Accommodations;
  }, [currentPage, pageOrder]);

  return {
    currentPage,
    displayAddons,
    nextPage,
    pathname,
    previousPage,
    selectAddonsBeforePayment,
    selectAdonsOnConfirmationPage,
  };
};
