import { t } from 'i18next';

import { Amenity } from '@data/types/common';
import {
  RawAccommodation,
  RawPackageAddon,
} from '@data/api/types/accommodation';
import { parseRawBoolean } from '@data/api/helpers';

import AccommodationSourceSettings from './AccommodationSourceSettings';
import { AccommodationUnit } from './AccommodationUnit';
import Image from './Image';
import MultilangText from './MultilangText';

function extractPackageName(roomName: string, roomTitle: string): string {
  if (roomName === roomTitle) {
    return t('standard-rate');
  }

  return roomTitle.substring(roomName.length + 2, roomTitle.length - 1);
}

export interface ObpPriceSettings {
  adult: Record<string, Record<string, number>>;
  kid: Record<string, Record<string, number>>;
}

export interface PackageAddon {
  description: MultilangText;
  id: string;
  name: MultilangText;
}

export default class Accommodation {
  accommodations!: AccommodationUnit[];
  adults!: number;
  allotmentBlockName!: string | null;
  amenities: Amenity[] = [];
  available!: number;
  beds!: number;
  bookableLimit!: number;
  bookedId: string[] = [];
  description!: string;
  featuredPackageAlt!: string;
  featuredPackageImage!: string;
  featuredPackageImageLarge!: string;
  id!: string;
  images!: Image[];
  isPackage!: boolean;
  isPrivate!: boolean;
  kids!: number;
  maxGuests!: number;
  maxRooms!: number;
  name!: string;
  nights!: number;
  notDerivedMaxDailyRate!: number;
  notDerivedRate!: number;
  numBeds!: number;
  obpPriceSettings!: ObpPriceSettings | null;
  obpPricingEnabled!: boolean;
  packageAddons!: PackageAddon[];
  packageDescription!: string;
  packageId!: string;
  packageName!: string;
  packageTerms!: string;
  policy!: string;
  rate!: number;
  rateId!: string;
  rateMin!: number;
  shortTitle!: string;
  sourceSettings!: AccommodationSourceSettings;
  title!: string;

  constructor(data?: RawAccommodation) {
    if (!data) {
      return;
    }

    this.id = data.room_type_id.toString();
    this.packageId = data.package_id?.toString();
    this.rateId = data.rate_id?.toString();
    this.packageTerms = data.package_terms || '';
    this.name = data.room_type_name;
    this.title = data.room_type_title;
    this.shortTitle = data.room_type_short_title;
    this.packageName = extractPackageName(this.name, this.title);
    this.description = data.room_type_desc;
    this.adults = parseInt(data.adults_inBasePrice);
    this.kids = parseInt(data.children_inBasePrice);
    this.notDerivedRate = data.not_derived_price
      ? data.not_derived_price.rate_basic
      : 0;
    this.notDerivedMaxDailyRate = data.not_derived_price
      ? data.not_derived_price.rate_max
      : 0;

    this.allotmentBlockName = data.allotment_block_name || null;
    this.rate = data.rate_basic;
    this.rateMin = data.rate_min;
    this.isPrivate = parseRawBoolean(data.is_private);
    this.packageDescription = data.package_desc || '';
    this.isPackage = parseRawBoolean(data.is_package);
    this.featuredPackageAlt = data.package_src_alt || '';
    this.featuredPackageImage = data.package_src || '';
    this.featuredPackageImageLarge = data.package_src_big || '';
    this.bookableLimit = parseInt(data.bookable_limit);

    this.packageAddons = [];
    if (data.package_addons) {
      this.packageAddons = data.package_addons.map(
        (rawPackageAddon: RawPackageAddon) => {
          return {
            description: new MultilangText(rawPackageAddon.addon_description),
            id: rawPackageAddon.addon_id,
            name: new MultilangText(rawPackageAddon.addon_name),
          };
        }
      );
    }

    this.obpPricingEnabled =
      Object.keys(data.guests?.kid || {}).length > 0 ||
      Object.keys(data.guests?.adult || {}).length > 0;
    this.obpPriceSettings =
      data.guests && this.obpPricingEnabled
        ? {
            adult: !Array.isArray(data.guests.adult) ? data.guests.adult : {},
            kid: !Array.isArray(data.guests.kid) ? data.guests.kid : {},
          }
        : null;

    this.sourceSettings = {
      bookableLimit: parseInt(data.bookable_limit),
      canSelectSpecificRoom:
        !!data.accommodations && Object.keys(data.accommodations).length > 0,
      capacity: parseInt(data.max_guests),
      maxRooms: data.max_rooms,
      roomTypeId: data.room_type_id,
      title: data.room_type_title,
    };

    this.accommodations = Object.values(data.accommodations || []).map(
      ({
        description,
        group_name,
        hasDescription,
        images,
        name,
        room_id,
        room_position,
      }) => ({
        description,
        group_name: group_name || '',
        hasDescription,
        images: images.map((image) => new Image(image)),
        name,
        room_id: room_id.toString(),
        room_position: room_position.toString(),
      })
    );

    // This policy value depends on an implementation from the Smart Policies team in MFD
    // it should be implemented in the future
    this.policy = '';

    const { detailed_rates = [] } = data;
    this.nights = detailed_rates.length;

    this.maxGuests = parseInt(data.max_guests);
    this.maxRooms = data.max_rooms;
    this.numBeds = parseInt(data.num_beds || '1');
    this.available = data.num_available_now;
    this.images = data.photos_gallery.map((src: string, index: number) => ({
      alt: data.photos_gallery_alt[index],
      src: src,
      thumb: src,
    }));

    this.amenities =
      data.features?.map((name: string) => ({
        name,
      })) || [];
  }
}
