import { system } from './system';
import { EnvironmentType, isEnvirontmentType } from './types';

type System = Partial<typeof system>;

const getEnvFromHostname = (hostname: string): EnvironmentType => {
  const DEVBOX_REGEXP_ON_DEV = /.*\.mfd-devbox-.+\.cloudbeds-dev.com/;
  const DEVBOX_REGEXP_ON_STAGE = /.*\.mfd-devbox-.+\.cloudbeds-stage.com/;
  const LOCAL_REGEXP = /.*\.cloudbeds-local.com/;
  const STAGE_REGEXP = /.*\.cloudbeds-stage.com/;

  if (
    DEVBOX_REGEXP_ON_DEV.test(hostname) ||
    DEVBOX_REGEXP_ON_STAGE.test(hostname)
  )
    return 'devbox';
  if (LOCAL_REGEXP.test(hostname)) return 'local';
  if (STAGE_REGEXP.test(hostname)) return 'stage';
  return 'prod';
};

const getEnvFromParameters = (
  currentScriptSrc?: string
): EnvironmentType | undefined => {
  if (currentScriptSrc) {
    const currentScriptURL = new URL(currentScriptSrc);
    const env = currentScriptURL.searchParams.get('env');
    if (env && isEnvirontmentType(env)) {
      return env;
    }
  }
  return undefined;
};

export const getEnvironment = (
  system: System,
  hostname: string,
  entryPoint: string
): EnvironmentType => {
  return (system.appEnv ||
    getEnvFromParameters(entryPoint) ||
    getEnvFromHostname(hostname)) as EnvironmentType;
};

const getIslandFromHostname = (hostname?: string): string => {
  if (hostname && hostname.indexOf('.cloudbeds') !== -1) {
    const regex = /([^]*?).cloudbeds/;
    const m = hostname.match(regex);
    return m && m.length > 0 ? m[1] : 'hotels';
  }
  return 'hotels';
};

const getIslandFromParameters = (
  currentScriptSrc?: string
): string | undefined => {
  if (currentScriptSrc) {
    const currentScriptURL = new URL(currentScriptSrc);
    const island = currentScriptURL.searchParams.get('island');
    if (island) return island;
  }
  return undefined;
};

export const getIsland = (
  system: System,
  hostname: string,
  entryPoint: string
): string => {
  return (
    system.island ||
    getIslandFromParameters(entryPoint) ||
    getIslandFromHostname(hostname)
  );
};
