import { useMemo } from 'react';

import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { removeNullishValues } from '@business/utils/object';
import TimeUtils from '@business/utils/time';

/**
 * This hook reads the location URL to get the App params to redirect to the correct URL
 * It consideres legacy App parameters that are sent as hash param
 */
export const useLegacyUrlParams = () => {
  const { hash } = useLocation();
  const [searchParams] = useSearchParams({});

  const hashParams = useMemo(() => {
    if (hash.length === 0) {
      return {};
    }

    return hash
      .substring(1)
      .split('&')
      .reduce((acc, paramPair) => {
        const { 0: key, 1: value } = paramPair.split('=');
        acc[key] = value;

        return acc;
      }, {});
  }, [hash]);

  // App is required to support both query and hash params.
  // In the event that the same param is specified in both hash and query params, deault to hash values.
  // Product team indicated no preference on this.
  const params = useMemo(() => {
    const code =
      hashParams['allotment_block_code'] ||
      hashParams['promo'] ||
      searchParams.get('allotment_block_code') ||
      searchParams.get('promo') ||
      undefined;

    const roomType = hashParams['room_type'] || searchParams.get('room_type');
    const roomIds = hashParams['rid'] || searchParams.get('rid');

    const rawCheckinDate = hashParams['checkin'] || searchParams.get('checkin');
    const rawCheckoutDate =
      hashParams['checkout'] || searchParams.get('checkout');

    const checkinDate = TimeUtils.fromIso(rawCheckinDate, { startOfDay: true });
    const checkoutDate = TimeUtils.fromIso(rawCheckoutDate, {
      startOfDay: true,
    });

    let checkin = checkinDate;
    // If checkin is not defined, then checkout is set to undefined as well
    // If checkin is defined and is valid, but checkout isn't, then checkout is set to checkin + 1 day
    let checkout = !checkin
      ? undefined
      : checkoutDate || TimeUtils.getNextDay(checkin);

    // If rid or roomType are provided, and no valid checkin / checkout combo are specified,
    // default to a 1-night stay starting tomorrow
    if ((roomType || roomIds) && !checkin && !checkout) {
      checkin = TimeUtils.getNextDay(TimeUtils.getToday());
      checkout = TimeUtils.getNextDay(checkin);
    }

    const ratePlans = hashParams['rate_plan'] || searchParams.get('rate_plan');
    const adults = hashParams['adults'] || searchParams.get('adults');
    const kids = hashParams['kids'] || searchParams.get('kids');

    const utm_source =
      hashParams['utm_source'] || searchParams.get('utm_source');

    const currency = hashParams['currency'] || searchParams.get('currency');

    return removeNullishValues({
      adults,
      checkin,
      checkout,
      code,
      currency,
      kids,
      ratePlans,
      roomIds,
      roomType,
      utm_source,
    });
  }, [hashParams, searchParams]);

  return params;
};
