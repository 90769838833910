export enum CARD_TYPES {
  VISA = 'visa',
  MASTER_CARD = 'master',
  AMEX = 'amex',
  DISCOVER = 'Discover',
  HIPER = 'hiper',
  EURO_CARD = 'Eurocard',
  JCB = 'jcb',
  DINERS_CLUB = 'diners',
  AURA = 'aura',
  ELO = 'elo',
  MAESTRO = 'maestro',
  DAN = 'dan',
  UNION_PAY = 'union_pay',
  UNKNOWN = 'unknown',
}

export type SupportedCreditCard = {
  code: CARD_TYPES;
  name: string;
};

export function getCreditCardType(
  card: string | number
): CARD_TYPES | undefined {
  // remove non-numeric characters from credit card number
  const cardNumber: string = card.toString().replace(/[^0-9]/g, '');

  const regexVisa = new RegExp('^4');
  const regexMastercard =
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
  const regexAmericanExpress = new RegExp('^3[47]');
  const regexDinersClub = new RegExp('^30[0-5]');
  const regexDiscover = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
  );
  const regexJCB = new RegExp('^35(2[89]|[3-8][0-9])');

  if (cardNumber.match(regexVisa)) {
    return CARD_TYPES.VISA;
  } else if (cardNumber.match(regexMastercard)) {
    return CARD_TYPES.MASTER_CARD;
  } else if (cardNumber.match(regexAmericanExpress)) {
    return CARD_TYPES.AMEX;
  } else if (cardNumber.match(regexDinersClub)) {
    return CARD_TYPES.DINERS_CLUB;
  } else if (cardNumber.match(regexDiscover)) {
    return CARD_TYPES.DISCOVER;
  } else if (cardNumber.match(regexJCB)) {
    return CARD_TYPES.JCB;
  } else {
    return undefined;
  }
}
