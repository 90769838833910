import { useTheme } from '@chakra-ui/react';

export const useColors = () => {
  const theme = useTheme();

  const getThemeColor = (name?: string): string | undefined => {
    if (!name) return undefined;
    if (
      name.indexOf('#') === 0 ||
      name.indexOf('rgb(') === 0 ||
      name.indexOf('rgba(') === 0
    ) {
      return name;
    }

    const colorParts = name.split('.');
    const colorName = colorParts[0];
    const colorShade = colorParts.length > 1 ? colorParts[1] : 500;
    return theme.colors[colorName]?.[colorShade];
  };

  return {
    getThemeColor,
  };
};
