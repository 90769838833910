//import { notificationError } from "@view/utils/notifications";
import type { AxiosError } from 'axios';

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return (error as AxiosError)?.isAxiosError;
}

export function onApiError(url: string, params: any, e: unknown) {
  // TODO: Remove this line when the API is ready.
  if (url === '/addons') return e;

  if (isAxiosError(e)) {
    const statusCode = Number(e.response?.status);

    if (statusCode >= 400 && statusCode < 500) {
      // need to handle errors here.

      const { message } = e;

      //notificationError(message);
      return e;
    }
  }
  //notificationError("Internal server error");

  return e;
}
