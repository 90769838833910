import { IPersistentStore } from '../IPersistentStore';

import SearchPanel from './SearchPanel';

export default class Components implements IPersistentStore {
  searchPanel = new SearchPanel();

  get storageKey(): string {
    return 'components';
  }

  load(): void {
    this.searchPanel.load();
  }

  save(): void {
    this.searchPanel.save();
  }
}
