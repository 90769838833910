import { Box, BoxProps } from '@chakra-ui/react';
import { ThemeColors } from '@theme/foundations/colors';

type CardProps = {
  children?: BoxProps['children'];
  className?: string;
  'data-testid'?: string;
  isFeatured?: boolean;
  maxWidth?: BoxProps['maxWidth'];
  padding?: BoxProps['padding'];
};

const Card = ({
  padding = '24px',
  children,
  className,
  isFeatured,
  ...props
}: CardProps) => {
  const classNames = className ? `cb-card ${className}` : `cb-card`;
  return (
    <Box
      backgroundColor="white"
      border={isFeatured ? '2px solid' : '1px solid'}
      borderColor={
        isFeatured ? ThemeColors.neutral[500] : ThemeColors.neutral[300]
      }
      borderRadius="16px"
      boxShadow="0px 4px 64px rgba(119, 130, 149, 0.1)"
      className={classNames}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      padding={padding}
      position="relative"
      w="100%"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Card;
