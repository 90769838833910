import { AddonList } from '@data/models';
import { Language } from '@data/types/common';

import { apiCall, IRequestOptions } from '../axios';

export type AddonsSearchParams = {
  accommodations: {
    adults: number;
    bookedRoomId?: string | string[];
    kids: number;
    packageId: string;
    roomTypeId: string;
  }[];
  checkin: string;
  checkout: string;
  language: Language;
  propertyId: number;
};

export class AddonsService {
  static search(
    {
      accommodations,
      checkin,
      checkout,
      language,
      propertyId,
    }: AddonsSearchParams,
    options: IRequestOptions = {}
  ) {
    const payload = {
      lang: language,
      property_id: propertyId,
    };

    for (let i = 0; i < accommodations.length; i++) {
      const item = accommodations[i];
      payload[`rooms[${i}][room_type_id]`] = item.roomTypeId;
      payload[`rooms[${i}][package]`] = item.packageId;
      payload[`rooms[${i}][start_date]`] = checkin;
      payload[`rooms[${i}][end_date]`] = checkout;
      payload[`rooms[${i}][adults]`] = item.adults;
      payload[`rooms[${i}][kids]`] = item.kids;
    }

    return apiCall(AddonList)('post', '/addons', payload, options);
  }
}
