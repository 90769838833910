import { makeObservable, observable, runInAction } from 'mobx';

import {
  AvailabilityCalendar as AvailabilityCalendarModel,
  Property,
} from '@data/models';
import { AvailabilityCalendarService } from '@data/api';
import TimeUtils from '@business/utils/time';
import { RawAvailabilityCalendar } from '@data/api/types/availability-calendar';
import { sortObjectByKeys } from '@business/utils/object';
import { isAllotmentBlockCode } from '@business/utils/promocodes';
import Store from '@data/stores/Store';

type AvailabilityCalendarPayload = {
  allotment_block_code?: string;
  end_date: string;
  promo_code?: string;
  property_id: number;
  start_date: string;
};

const CACHE_SIZE = 2;
const DEFAULT_BATCH_SIZE = 2;

export default class AvailabilityCalendar {
  batchSize = DEFAULT_BATCH_SIZE;
  @observable model: AvailabilityCalendarModel | null = null;

  constructor() {
    makeObservable(this);
  }

  private createPayload = (
    propertyId: Property['id'],
    start: Date,
    end: Date,
    code?: string
  ): AvailabilityCalendarPayload => ({
    end_date: TimeUtils.format(end),
    property_id: propertyId,
    start_date: TimeUtils.format(start),
    ...(code
      ? isAllotmentBlockCode(code)
        ? { allotment_block_code: code }
        : { promo_code: code }
      : {}),
  });

  private hasCodeChanged = (newCode?: string) =>
    (this.model?.code || '') !== (newCode || '');

  private mergeData = (
    data: RawAvailabilityCalendar,
    start: Date,
    end: Date
  ) => {
    const defaults = Array.from(
      Array(TimeUtils.getDiff(end, start) + 1)
    ).reduce<RawAvailabilityCalendar>(
      (acc, _, i) => ({
        ...acc,
        [TimeUtils.toDateString(TimeUtils.getDateOffset(i, 0, 0, start))]: [],
      }),
      {}
    );

    return sortObjectByKeys({
      ...defaults,
      ...this.model?.data,
      ...data,
    });
  };

  private needsToSearch = (start: Date, end: Date) => {
    if (!this.model) {
      return true;
    }

    // Interval was already requested
    const isCached =
      TimeUtils.isSameOrAfter(start, this.model.checkin) &&
      TimeUtils.isSameOrBefore(end, this.model.checkout);

    return (
      !isCached &&
      (TimeUtils.isSameOrBefore(start, this.model.checkin) ||
        TimeUtils.isSameOrAfter(end, this.model.checkout))
    );
  };

  private refineInterval = (
    start: Date,
    end: Date
  ): { end: Date; start: Date } => {
    if (!this.model) {
      return { end, start };
    }

    // New interval is not adyacent to the model's interval and it's after the model's checkout date, complete interval
    if (TimeUtils.isAfter(start, this.model.checkout)) {
      return { end, start: TimeUtils.getNextDay(this.model.checkout) };
    }

    // New interval is not adyacent to the model's interval and it's before the model's checkin date, complete interval
    if (TimeUtils.isBefore(end, this.model.checkin)) {
      return { end: TimeUtils.getPrevDay(this.model.checkin), start };
    }

    // Start date is between model's checkin and checkout date, fetch only necessary data
    if (TimeUtils.isBetween(start, this.model.checkin, this.model.checkout)) {
      return {
        end,
        start: TimeUtils.getNextDay(this.model.checkout),
      };
    }

    // End date is between model's checkin and checkout date, fetch only necessary data
    if (TimeUtils.isBetween(end, this.model.checkin, this.model.checkout)) {
      return {
        end: TimeUtils.getPrevDay(this.model.checkin),
        start,
      };
    }

    // Interval doesn't overlap with model's interval, fetch all data in that interval
    return { end, start };
  };

  private search = async ({
    code,
    endDate,
    propertyId,
    startDate,
  }: {
    code?: string;
    endDate: Date;
    propertyId: Property['id'];
    startDate: Date;
  }) => {
    if (TimeUtils.isAfter(startDate, endDate)) {
      throw new Error('AvailabilityCalendar:search - Invalid date range');
    }

    if (this.hasCodeChanged(code)) {
      runInAction(() => {
        this.model = null;
      });
    }

    if (!this.needsToSearch(startDate, endDate)) {
      return;
    }

    const { end, start } = this.refineInterval(startDate, endDate);

    const intervals = this.splitInterval(start, end);

    intervals.forEach(async ({ end, start }) => {
      const payload = this.createPayload(propertyId, start, end, code);

      const result = await AvailabilityCalendarService.search(payload);

      const data = this.mergeData(result.data, result.checkin, result.checkout);

      const checkin = this.model?.checkin
        ? TimeUtils.min(this.model.checkin, result.checkin)
        : result.checkin;

      const checkout = this.model?.checkout
        ? TimeUtils.max(this.model.checkout, result.checkout)
        : result.checkout;

      runInAction(() => {
        this.model = {
          checkin,
          checkout,
          code: result.code,
          data,
        };
      });
    });
  };

  searchMonths = async ({
    code,
    date,
    months = 1,
    propertyId,
  }: {
    code?: string;
    date: Date;
    months?: number;
    propertyId: number;
  }) => {
    const firstAvailableDate = TimeUtils.getToday();

    if (!Store.property.model?.allowSameDayReservation) {
      firstAvailableDate.setDate(firstAvailableDate.getDate() + 1);
    }

    const startOfDateMonth = TimeUtils.getStartOfMonthDate(date);

    const startDate = TimeUtils.isBefore(startOfDateMonth, firstAvailableDate)
      ? firstAvailableDate
      : startOfDateMonth;

    const endDate = TimeUtils.getEndOfMonthDate(date, months + CACHE_SIZE);

    await this.search({
      code,
      endDate,
      propertyId,
      startDate,
    });
  };

  private splitInterval = (startDate: Date, endDate: Date) => {
    if (this.batchSize === 0) {
      return [{ end: endDate, start: startDate }];
    }

    const monthDiff = TimeUtils.getMonthDiff(endDate, startDate) + 1;
    const batches = Math.ceil(monthDiff / this.batchSize);

    const intervals: { end: Date; start: Date }[] = [];

    for (let i = 0; i < batches; i++) {
      const start = TimeUtils.getStartOfMonthDate(
        TimeUtils.getDateOffset(0, this.batchSize * i, 0, startDate)
      );

      const end = TimeUtils.getEndOfMonthDate(
        TimeUtils.getDateOffset(0, this.batchSize - 1, 0, start)
      );

      intervals.push({
        end: TimeUtils.isAfter(end, endDate) ? endDate : end,
        start: TimeUtils.isBefore(start, startDate) ? startDate : start,
      });
    }

    return intervals;
  };
}
