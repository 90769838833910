import { AccommodationTypeList } from '@data/models';

import { apiCall, IRequestOptions } from '../axios';

export class AccommodationsTypeService {
  static load(propertyId: number, options: IRequestOptions = {}) {
    return new Promise<AccommodationTypeList>((resolve, reject) => {
      const url = '/rooms';
      const params = {
        checkin: '1970-01-01',
        checkout: '1970-01-02',
        widget_property: propertyId,
      };
      apiCall(AccommodationTypeList)('post', url, params, options)
        .then((res) => {
          const accommodationList = res;
          resolve(accommodationList);
        })
        .catch(reject);
    });
  }
}
