import { ApiMaData } from '@data/api/types/ma-data';

export type ComparativeRate = {
  price: number;
  sourceName: string;
};

export type ComparativeRateSummary = {
  accommodationName: string;
  directRate: number;
  rates: ComparativeRate[];
};

export default class RateCheckerData {
  comparativeRateSummaries: ComparativeRateSummary[];

  constructor(data?: boolean | ApiMaData[]) {
    if (!data || typeof data === 'boolean') {
      this.comparativeRateSummaries = [];
    } else {
      this.comparativeRateSummaries = (data as ApiMaData[]).map(
        (roomInfo: ApiMaData) => ({
          accommodationName: roomInfo.name,
          directRate: roomInfo.direct,
          rates: roomInfo.rates.map((rate) => {
            return {
              price: rate.channel_price,
              sourceName: rate.channel_name,
            };
          }),
        })
      );
    }
  }
}
