import { RawUnavailableAccommodation } from '@data/api/types/unavailable-accommodation';
import { Amenity } from '@data/types/common';

import Image from './Image';

export default class UnavailableAccommodation {
  // Field is not returned by API, adding it here just to be able to use the AccommodationDetails component for UnavaiilableAccommodation
  adults = 0;
  // Field is not returned by API, adding it here just to be able to use the AccommodationDetails component for UnavaiilableAccommodation
  amenities: Amenity[] = [];
  description!: string;
  id!: string;
  images!: Image[];
  // Field is not returned by API, adding it here just to be able to use the AccommodationDetails component for UnavaiilableAccommodation
  isPrivate?: boolean;
  // Field is not returned by API, adding it here just to be able to use the AccommodationDetails component for UnavaiilableAccommodation
  kids = 0;
  maxGuests!: number;
  name!: string;
  // Field is not returned by API, adding it here just to be able to use the AccommodationDetails component for UnavaiilableAccommodation
  numBeds?: number;
  title!: string;

  constructor(data: RawUnavailableAccommodation) {
    this.description = data.room_type_desc;
    this.id = data.room_type_id.toString();
    this.maxGuests = parseInt(data.max_guests);
    this.name = data.room_type_name;
    this.title = data.room_type_title;

    this.images = data.photos_gallery.map((src: string, index: number) => ({
      alt: data.photos_gallery_alt[index],
      src: src,
      thumb: src,
    }));
  }
}
