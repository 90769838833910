import TimeUtils from '@business/utils/time';
import { AvailabilityCalendar } from '@data/models';

import { apiCall, IRequestOptions } from '../axios';

export interface AvailabilityCalendarParams {
  allotment_block_code?: string;
  end_date: string;
  lang?: string;
  promo_code?: string;
  property_id: number;
  room_types?: number[];
  start_date: string;
}

export class AvailabilityCalendarService {
  static async search(
    params: AvailabilityCalendarParams,
    options: IRequestOptions = {}
  ) {
    const calendar = await apiCall(AvailabilityCalendar)(
      'post',
      '/availability_calendar',
      params,
      options
    );

    calendar.checkin = TimeUtils.fromString(params.start_date);
    calendar.checkout = TimeUtils.fromString(params.end_date);
    calendar.code = params.promo_code || params.allotment_block_code || '';

    return calendar;
  }
}
