import { EnvironmentType } from './types';

class System {
  amplifyMetasearchConnectorServiceApiBase?: string;
  apiBase?: string;
  appEnv?: EnvironmentType;
  env?: 'development' | 'test' | 'production';
  experience?: 'hosted' | 'immersive';
  island?: string;
  launchDarklyApiKey?: string;
  marketingInsightesServiceApiBase?: string;
  staticFiles?: string;

  static create = () => new System();

  get isHostedExperience(): boolean {
    return this.experience === 'hosted';
  }

  get isImmersiveExperience(): boolean {
    return this.experience === 'immersive';
  }

  load = (settings: Partial<System>) =>
    Object.keys(settings).forEach((key) => {
      this[key] = settings[key];
    });
}

export const system = System.create();
