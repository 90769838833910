// Numbers

export type NumberString = `${number}`;

// Booleans

export type BooleanNumber = 1 | 0;

export type BooleanString = '1' | '0';

export type YesNoBoolean = 'Y' | 'N';

// Dates

type OneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type Digit = OneToNine | 0;

export type DD = `${0}${OneToNine}` | `${1 | 2}${Digit}` | `3${0 | 1}`;
export type MM = `0${OneToNine}` | `1${0 | 1 | 2}`;
export type YYYY = `19${Digit}${Digit}` | `20${Digit}${Digit}`;

export type DateString = `${YYYY}-${MM}-${DD}`;

const DATE_STRING_REGEX = /\d{4}-\d{2}-\d{2}/;

export const isDateString = (input: string): input is DateString =>
  DATE_STRING_REGEX.test(input);
