import { getEnvironment, getIsland } from './helpers';
import { system } from './system';
import { EnvRecord } from './types';

const isLocalDevelopment = system.env === 'development';

const jsEntryPoint = (document.currentScript as HTMLScriptElement)?.src || '';

export const FILES = jsEntryPoint.split('/static/js/')[0];
export const ISLAND = getIsland(system, location.hostname, jsEntryPoint);
export const ENVIRONMENT = getEnvironment(
  system,
  location.hostname,
  jsEntryPoint
);

const apiMap: EnvRecord = {
  // @TECHDEBT: location.origin can't be used in the Immersive Experience
  devbox: `${location.origin}/booking/`,
  local: 'https://hotels.cloudbeds-local.com/booking/',
  prod: `https://${ISLAND}.cloudbeds.com/booking/`,
  stage: `https://${ISLAND}.cloudbeds-stage.com/booking/`,
};

const marketingInsightsService: EnvRecord = {
  devbox: 'https://api.cloudbeds-stage.com/marketing-insights',
  local: 'https://api.cloudbeds-stage.com/marketing-insights',
  prod: 'https://api.cloudbeds.com/marketing-insights',
  stage: 'https://api.cloudbeds-stage.com/marketing-insights',
};

const amplifyMetasearchConnectorService: EnvRecord = {
  devbox: 'https://api.cloudbeds-stage.com/amplify-metasearch-connector',
  local: 'https://api.cloudbeds-stage.com/amplify-metasearch-connector',
  prod: 'https://api.cloudbeds.com/amplify-metasearch-connector',
  stage: 'https://api.cloudbeds-stage.com/amplify-metasearch-connector',
};

const launchdarklyKeyMap: EnvRecord = {
  devbox: '6622ce48f758a10f98ea6c92',
  local: '6622ce48f758a10f98ea6c92',
  prod: '6622cd9d6be9690f3ebae4e4',
  stage: '6622ce48f758a10f98ea6c92',
};

export function getApiBaseURL(): string {
  return system.apiBase || apiMap[ENVIRONMENT];
}

// @TECHDEBT: do we need this? how should we resolve it in the Immersive Experience
// manually set webpack public path
if (!isLocalDevelopment) {
  if (typeof window !== 'undefined') {
    window['__webpack_public_path__'] = FILES + '/';
  }
}

export const MARKETING_INSIGHTS_SERVICE_API =
  system.marketingInsightesServiceApiBase ||
  marketingInsightsService[ENVIRONMENT];

export const AMPLIFY_METASEARCH_CONNECTOR_SERVICE_API =
  system.amplifyMetasearchConnectorServiceApiBase ||
  amplifyMetasearchConnectorService[ENVIRONMENT];

export const LAUNCH_DARKLY_API_KEY =
  system.launchDarklyApiKey || launchdarklyKeyMap[ENVIRONMENT];
