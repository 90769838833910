import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { FILES } from '@config/index';
import { system } from '@config/system';

function init() {
  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      backend: {
        allowMultiLoading: false,
        crossDomain: true,
        loadPath: `${FILES}/locales/{{ns}}/{{lng}}.json`,
      },
      defaultNS: 'common',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      lng: 'en',
      missingKeyHandler: (lng, ns, key) => {
        throw new Error(
          `Missing i18n key ${[lng].flat().join(',')}:${ns}:${key}`
        );
      },
      ns: ['common', 'validation'],
      partialBundledLanguages: true,
      preload: ['en'],
      saveMissing: false,
    });
}

if (system.env !== 'test') {
  init();
}

export default i18n;
