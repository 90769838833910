import { RawAddon } from '@data/api/types/addon';
import { parseRawBoolean } from '@data/api/helpers';

import Image from './Image';
import MultilangText from './MultilangText';
import ChargeType from './ChargeType';

type MatchItemType = {
  adults: number;
  kids: number;
  packageId: string;
  roomId: string;
};
export default class Addon {
  accommodationBookedId!: string;
  adults!: number;
  available!: string;
  bookedId: string[] = [];
  chargeType!: ChargeType;
  currentStock!: string;
  dailyRates!: RawAddon['dailyRates'];
  description!: MultilangText;
  doNotTrack!: boolean;
  feesArray!: RawAddon['feesArray'];
  feesGroupedById!: RawAddon['feesGroupedById'];
  grandTotal!: number;
  id!: string;
  images!: Image[];
  isFixedRate!: boolean;
  kids!: number;
  maxQtyPerReservation!: number;
  name!: MultilangText;
  order!: number;
  packageId!: string;
  postingStrategy!: string;
  priceSettings!: string;
  productId!: string;
  productType!: string;
  quantity!: number;
  rate!: number;
  rateWithInclusive!: number;
  roomId!: string;
  roomRateid!: string;
  stopSelling!: RawAddon['stopSelling'];
  taxesArray!: RawAddon['taxesArray'];
  taxesGroupedById!: RawAddon['taxesGroupedById'];
  totalFees!: number;
  totalRate!: number;
  totalRateWithInclusive!: number;
  totalTaxes!: number;
  weekAvailability!: number[];

  constructor(data?: RawAddon) {
    if (!data) {
      return;
    }

    this.id = data.addonId.toString();
    this.productId = data.productId.toString();
    this.roomId = data.roomId;
    this.packageId = data.packageId.toString();
    this.name = new MultilangText(data.addonName);
    this.images = data.addonPhotos.map((addonPhoto) => new Image(addonPhoto));
    this.description = new MultilangText(data.addonDescription);
    this.chargeType = (data.chargeType?.replaceAll('_', '-') ||
      'per-reservation') as ChargeType;
    this.weekAvailability = data.weekAvailability;
    this.maxQtyPerReservation = data.maxQtyPerReservation || 0;
    this.available = data.available;
    this.isFixedRate = parseRawBoolean(data.isFixedPrice);
    this.quantity = data.qty;
    this.rate = data.price;
    this.rateWithInclusive = data.priceWithInclusive;
    this.totalRate = data.totalPrice;
    this.totalRateWithInclusive = data.totalPriceWithInclusive;
    this.totalTaxes = data.totalTaxes;
    this.totalFees = data.totalFees;
    this.grandTotal = data.grandTotal;
    this.taxesGroupedById = data.taxesGroupedById;
    this.feesGroupedById = data.feesGroupedById;
    this.taxesArray = data.taxesArray;
    this.feesArray = data.feesArray;
    this.dailyRates = data.dailyRates;
    this.priceSettings = data.priceSettings;
    this.postingStrategy = data.postingStrategy;
    this.currentStock = data.currentStock;
    this.doNotTrack = parseRawBoolean(data.doNotTrack);
    this.stopSelling = data.stopSelling;
    this.productType = data.productType;
    this.adults = data.adults;
    this.kids = data.kids;
    this.order = Number.isNaN(data.order) ? -1 : Number(data.order);
  }

  static matchesItem = (a: MatchItemType, b: MatchItemType) =>
    a.roomId === b.roomId &&
    a.packageId === b.packageId &&
    a.adults === b.adults &&
    a.kids === b.kids;
}
