import bind from 'bind-decorator';
import { configure } from 'mobx';

import { setupQueryCache } from '@data/api/queryClient';
import { asyncSuspense } from '@data/other/asyncSuspense';
import { FeatureFlag, FeatureFlagManager } from '@business/feature-flags';

import Components from './Components';
import Environment from './Environment';
import Property from './Property';
import Session from './Session';
import { persistentPropertyStorage } from './PersistentPropertyStorage';

configure({ isolateGlobalState: true });

export type LoadPersistentDataArgs = {
  checkin?: string;
  checkout?: string;
  promoCode?: string;
};
export class Store {
  components = new Components();
  environment = new Environment();
  property = new Property();
  session = new Session();

  clearPersistentData() {
    if (!this.isUsePersistentDataEnabled) return;
    persistentPropertyStorage.clear();
  }

  @bind async init() {
    await setupQueryCache();
    return this;
  }

  private get isUsePersistentDataEnabled() {
    return FeatureFlagManager.isEnabled(FeatureFlag.usePersistentData);
  }

  loadPersistentData({
    checkin,
    checkout,
    promoCode,
  }: LoadPersistentDataArgs): boolean {
    if (!this.isUsePersistentDataEnabled) return false;

    // Verify the data from "SearchPanel" store without apply it
    // If the data is different, do not load the data
    const savedSearchParams = persistentPropertyStorage.getSearchParams();

    const savedCheckin = savedSearchParams['checkin'];
    const savedCheckout = savedSearchParams['checkout'];
    const savedCode = savedSearchParams['promo'];

    // If there no stored checkin and checkout, do not load the data
    if (!checkin || !checkout || !savedCheckin || !savedCheckout) {
      return false;
    }

    // Verify if the checkin, checkout and promocode are the same as the stored values
    if (
      checkin !== savedCheckin ||
      checkout !== savedCheckout ||
      promoCode !== savedCode
    ) {
      return false;
    }

    this.property.load();
    this.session.load();
    this.components.load();

    return true;
  }

  savePersistentData() {
    if (!this.isUsePersistentDataEnabled) return;
    persistentPropertyStorage.setSearchParams(window.location.search);
    this.property.save();
    this.session.save();
    this.components.save();
  }
}

const store = new Store();

export const initializeStore = asyncSuspense(store.init, 'store');

// Used by the integration tests to reset the store before mounting the App
export const resetStore = () => {
  store.components = new Components();
  store.environment = new Environment();
  store.property = new Property();
  store.session = new Session();
  store.clearPersistentData();
};

export interface StoreProps {
  store: Store;
}

export default store;
