import _ from 'lodash';

import { css } from '@emotion/react';
import Text from '@molecules/Text';
import { getFontSize } from '@theme/foundations/fontSizes';
import lineHeights from '@theme/foundations/lineHeights';

import { ThemeColors } from '../theme/foundations/colors';

import { colorIsDark } from './colors';

export const backgroundStyles = (props: { background: string }) => {
  const color = _.get(ThemeColors, props.background);

  if (!color) {
    return css``;
  }

  return css`
    background-color: ${color};
  `;
};

export const colorStyle = (color: string, contrastColor?: string) => {
  if (contrastColor) {
    const backgroundColor = _.get(ThemeColors, contrastColor);
    const isDark = colorIsDark(backgroundColor);

    if (isDark) {
      return css`
        color: ${ThemeColors.white[500]};
      `;
    } else {
      return css`
        color: ${ThemeColors.black[500]};
      `;
    }
  }

  const colorValue = _.get(ThemeColors, color);

  return css`
    color: ${colorValue};
  `;
};

/**
 * The max-height for a Text component is a combination of the font-size, the line-height and the max amount of lines.
 * These values could be taken from the global theme, but this would allow the developer to correctly configure the line-clamp depending on the use case.
 * Vertical padding should be considered in this calculation as well, but there are no implementations that currently use it.
 */
export const calculateLineClamp = ({
  fontSize = getFontSize('md'),
  lineHeight = lineHeights['base'],
  noOfLines,
}: {
  fontSize?: number;
  lineHeight?: number;
  noOfLines: number;
}): React.ComponentProps<typeof Text>['lineClamp'] => ({
  maxHeight: `${fontSize * lineHeight * noOfLines}px`,
  noOfLines,
});
