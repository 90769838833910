import { Language } from '@data/types/common';

export const languagesToLocales: Record<Language, string> = {
  ar: 'ar',
  cn: 'zh',
  cs: 'cs',
  de: 'de',
  el: 'el',
  en: 'en-US',
  es: 'es',
  et: 'et',
  fi: 'fi',
  fr: 'fr',
  he: 'he',
  hu: 'hu',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  lt: 'lt',
  nl: 'nl',
  no: 'no',
  pl: 'pl',
  'pt-br': 'pt-br',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sv: 'sv',
  th: 'th',
  ua: 'uk',
  vn: 'vi',
};

export const defaultLocale = 'en-US';
