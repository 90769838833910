export const languages = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Português',
    value: 'pt-br',
  },
  {
    text: 'Español',
    value: 'es',
  },
  {
    text: 'Русский',
    value: 'ru',
  },
  {
    text: 'Українська',
    value: 'ua',
  },
  {
    text: 'Italiano',
    value: 'it',
  },
  {
    text: 'Deutsch',
    value: 'de',
  },
  {
    text: 'Français',
    value: 'fr',
  },
  {
    text: 'Eesti',
    value: 'et',
  },
  {
    text: 'Polski',
    value: 'pl',
  },
  {
    text: 'Nederlands',
    value: 'nl',
  },
  {
    text: 'Suomi',
    value: 'fi',
  },
  {
    text: 'Ελληνική',
    value: 'el',
  },
  {
    text: '日本語',
    value: 'ja',
  },
  {
    text: 'ภาษาไทย',
    value: 'th',
  },
  {
    text: '中文',
    value: 'cn',
  },
  {
    text: 'עברית',
    value: 'he',
  },
  {
    text: '한국어',
    value: 'ko',
  },
  {
    text: 'Svenska',
    value: 'sv',
  },
  {
    text: 'Norsk',
    value: 'no',
  },
  {
    text: 'Lietuvių',
    value: 'lt',
  },
  {
    text: 'Tiếng Việt',
    value: 'vn',
  },
  {
    text: 'Magyar',
    value: 'hu',
  },
  {
    text: 'Slovakia',
    value: 'sk',
  },
  {
    text: 'Čeština',
    value: 'cs',
  },
  {
    text: 'Română',
    value: 'ro',
  },
  {
    text: 'العربية',
    value: 'ar',
  },
] as const;
