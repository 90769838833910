import React, { Suspense, useMemo } from 'react';

import { MobXProviderContext } from 'mobx-react';
import { QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';

import queryClient from '@data/api/queryClient';
import Root from '@view/pages/Root';
import i18n from '@data/other/i18n';
import { initializeStore } from '@data/stores/Store';

const AppProvider = React.lazy(
  () => import(/* webpackChunkName: "app-provider" */ '@view/providers/app')
);

type Props = { storeLoader: ReturnType<typeof initializeStore> };

const AppInner = ({ storeLoader }: Props) => {
  const store = useMemo(() => storeLoader(), [storeLoader]);

  return (
    <MobXProviderContext.Provider value={{ store }}>
      <QueryClientProvider client={queryClient}>
        <Suspense>
          <AppProvider>
            <I18nextProvider i18n={i18n}>
              <Root />
            </I18nextProvider>
          </AppProvider>
        </Suspense>
      </QueryClientProvider>
    </MobXProviderContext.Provider>
  );
};

export default AppInner;
