import { AccommodationData } from '@data/types/AccommodationData';
import { AddonData } from '@data/types/AddonData';

import { GA4PurchaseEvent, TrackedAccommodation, TrackedAddon } from './consts';

const translateAddon = (itemWrapper): TrackedAddon => {
  const {
    item: {
      id,
      productId,
      roomId,
      packageId,
      name: { en },
      chargeType,
      quantity,
      rate,
    },
  } = itemWrapper;
  return {
    amount: itemWrapper.amount,
    item: {
      chargeType,
      id,
      name: en,
      packageId,
      productId,
      quantity,
      rate,
      roomId,
    },
    type: itemWrapper.type,
  };
};

const translateAccommodation = (itemWrapper) => {
  const {
    item: {
      id,
      packageId,
      rateId,
      name,
      packageName,
      adults,
      kids,
      beds,
      rate,
      nights,
      isPackage,
      isPrivate,
    },
  } = itemWrapper;
  return {
    adults: itemWrapper.adults,
    amount: itemWrapper.amount,
    item: {
      adults,
      beds,
      id,
      isPackage,
      isPrivate,
      kids,
      name,
      nights,
      packageId,
      packageName,
      rate,
      rateId,
    },
    kids: itemWrapper.kids,
    type: itemWrapper.type,
  };
};

export const translateCartItem = (
  itemWrapper
): TrackedAccommodation | TrackedAddon => {
  return itemWrapper?.type === 'accommodation'
    ? translateAccommodation(itemWrapper)
    : translateAddon(itemWrapper);
};

function isAddonData(item: AccommodationData | AddonData): item is AddonData {
  return (item as AddonData).addonId !== undefined;
}

export const reservationItemToGA4Item = (
  item: AccommodationData | AddonData,
  affiliation: string
): GA4PurchaseEvent['items'][number] => {
  return {
    affiliation,
    item_id: isAddonData(item) ? item.addonId : item.roomId,
    item_name: item.name || '',
    price: item.price || 0,
    quantity: item.amount,
  };
};
