import React from 'react';

import { Flex } from '@chakra-ui/react';

import Loader from '.';

export const PageLoader = (props: React.ComponentProps<typeof Loader>) => (
  <Flex
    alignItems="center"
    bgColor="background.500"
    flexGrow={1}
    height="100%"
    justifyContent="center"
    minHeight="500px"
    width="100%"
  >
    <Loader {...props} />
  </Flex>
);
