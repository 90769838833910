import { IPersistentStore } from '../IPersistentStore';
import { persistentPropertyStorage } from '../PersistentPropertyStorage';

export default class Session implements IPersistentStore {
  cartToken: string | undefined = undefined;

  get storageKey(): string {
    return 'session';
  }

  load(): void {
    const loadedData = persistentPropertyStorage.getItem(this.storageKey);
    if (loadedData) {
      const parsedData = JSON.parse(loadedData);
      this.cartToken = parsedData.cartToken;
    }
  }

  save(): void {
    persistentPropertyStorage.setItem(this.storageKey, JSON.stringify(this));
  }

  setCartToken(value: string | undefined) {
    this.cartToken = value;
  }
}
