/**
 * Set System settings at the very begining before importing the application
 */
import '@config/system/load-from-process';
import '@config/index';

import { initializeStore } from '@data/stores/Store';

import AppInner from './AppInner';

import './App.scss';

const storeLoader = initializeStore();

const App = () => <AppInner storeLoader={storeLoader} />;

export default App;
