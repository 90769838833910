import { RawAvailabilityCalendar } from '@data/api/types/availability-calendar';

// @TECHDEBT: this function can be replaced by isDateString()
const isDate = (date: string): boolean => /^\d{4}-\d{2}-\d{2}$/.test(date);

export class AvailabilityCalendar {
  checkin!: Date;
  checkout!: Date;
  code!: string;
  data!: RawAvailabilityCalendar;

  constructor(data?: RawAvailabilityCalendar) {
    this.data = {};

    if (data) {
      for (const key in data) {
        if (!isDate(key)) {
          continue;
        }

        this.data[key] = data[key];
      }
    }
  }
}

export default AvailabilityCalendar;
