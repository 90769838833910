import moment from 'moment-mini';

import { DateString, isDateString } from '@data/api/types/native';

function getDateOffset(
  dayOffset = 0,
  monthOffset = 0,
  yearOffset = 0,
  baseDate: string | number | Date = 0
): Date {
  const date = baseDate === 0 ? new Date() : new Date(baseDate);
  date.setHours(0, 0, 0, 0);
  const m = moment(date);
  if (dayOffset !== 0) m.add(dayOffset, 'day');
  if (monthOffset !== 0) m.add(monthOffset, 'month');
  if (yearOffset !== 0) m.add(yearOffset, 'year');
  return m.toDate();
}

const addDays = (date: DateString, days: number) =>
  toDateString(moment(date).add(days, 'day').toDate());

function getNextDay(date?: Date | DateString) {
  return getDateOffset(1, 0, 0, date);
}

function getPrevDay(date?: Date | DateString) {
  return getDateOffset(-1, 0, 0, date);
}

function format(
  date: Date | string | undefined,
  pattern = 'YYYY-MM-DD'
): string {
  return moment(date).format(pattern);
}

function fromIso(
  dateStr?: string | null,
  options: { startOfDay?: boolean } = {}
) {
  if (!dateStr || !isDateString(dateStr)) {
    return undefined;
  }

  // @TECHDEBT: We may need to fix this in the future for a better handling of different timezones
  return new Date(`${dateStr}${options.startOfDay ? 'T00:00:00' : ''}`);
}

function fromString(date: string, pattern = 'YYYY-MM-DD'): Date {
  return moment(date, pattern).toDate();
}

function getMonthDiff(date1: Date, date2?: Date): number {
  if (!date2) date2 = getToday();
  return moment(date1).diff(moment(date2), 'months', false);
}

function getDiff(date1: Date | DateString, date2?: Date | DateString): number {
  if (!date2) date2 = getToday();
  return moment(date1).diff(moment(date2), 'days', false);
}

function getEndOfMonthDate(date: Date, monthOffset = 1): Date {
  return new Date(date.getFullYear(), date.getMonth() + monthOffset, 0);
}

function getStartOfMonthDate(date: Date, monthOffset = 0): Date {
  return new Date(date.getFullYear(), date.getMonth() + monthOffset, 1);
}

function isBetween(date: Date, startDate: Date, endDate: Date): boolean {
  return moment(date).isBetween(startDate, endDate, 'day', '[]');
}

function isEqual(date1: Date | DateString, date2: Date | DateString): boolean {
  return moment(date1).isSame(moment(date2), 'day');
}

function isBefore(date1: Date | DateString, date2: Date | DateString): boolean {
  return moment(date1).isBefore(moment(date2), 'day');
}

function isSameOrBefore(
  date1: Date | DateString,
  date2: Date | DateString
): boolean {
  return moment(date1).isSameOrBefore(moment(date2), 'day');
}

function isAfter(date1: Date | DateString, date2: Date | DateString): boolean {
  return moment(date1).isAfter(moment(date2), 'day');
}

function isSameOrAfter(
  date1: Date | DateString,
  date2: Date | DateString
): boolean {
  return moment(date1).isSameOrAfter(moment(date2), 'day');
}

function isToday(date: Date | DateString): boolean {
  return moment(date).isSame(moment(), 'day');
}

function isPastDate(date: Date | DateString): boolean {
  return moment(date).isBefore(moment(), 'day');
}

function max(date1: Date, date2: Date): Date {
  return isBefore(date1, date2) ? date2 : date1;
}

function min(date1: Date, date2: Date): Date {
  return isBefore(date1, date2) ? date1 : date2;
}

function getDateString(
  date: Date | undefined,
  locale = 'en',
  size: 'lg' | 'md' | 'sm' = 'md'
) {
  if (!date) return '-';

  if (locale === 'pt-BR') {
    const day: string = new Intl.DateTimeFormat(locale, {
      day: 'numeric',
    }).format(date);
    const month: string = new Intl.DateTimeFormat(locale, {
      month: 'short',
    })
      .format(date)
      .replace('.', '');
    const year: string = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
    }).format(date);
    return `${day} ${month} ${year}`;
  }

  const formatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: size === 'sm' ? undefined : 'numeric',
  };

  return Intl.DateTimeFormat(locale, formatOptions).format(date);
}

function getToday(): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

const toDateString = (date: Date | string | undefined): DateString =>
  moment(date).format('YYYY-MM-DD') as DateString;

const TimeUtils = {
  addDays,
  format,
  fromIso,
  fromString,
  getDateOffset,
  getDateString,
  getDiff,
  getEndOfMonthDate,
  getMonthDiff,
  getNextDay,
  getPrevDay,
  getStartOfMonthDate,
  getToday,
  isAfter,
  isBefore,
  isBetween,
  isEqual,
  isPastDate,
  isSameOrAfter,
  isSameOrBefore,
  isToday,
  max,
  min,
  toDateString,
};

export default TimeUtils;
