import { parseRawBoolean } from '@data/api/helpers';
import type { Language } from '@data/types/common';
import { RawProperty } from '@data/api/types/property';
import {
  ThemeColors,
  ThemeColorsType,
  createContrastColorScale,
  createColorScale,
} from '@theme/foundations/colors';

import MultilangText from './MultilangText';

type EstimatedArrivalTime = {
  notAllowedHours?: string[];
  timeFormat?: string;
};

export type OptinSettings = {
  description?: MultilangText;
  isEnabled: boolean;
  optInText?: MultilangText;
  optOutText?: MultilangText;
};

export default class Settings {
  allowedBookingLanguages: Language[] = [];
  askCompanyName = false;
  askCompanyTaxID = false;
  askEstimatedArrivalTime = false;
  askGender = false;
  askGuestTaxID = false;
  askNationality = false;
  askPostalAddress = false;
  bookingEstimatedArrivalTime: EstimatedArrivalTime = {};
  colors: ThemeColorsType = { ...ThemeColors };
  coverImageUrl = '';
  displayAddons: 'preCheckout' | 'postCheckout';
  logoLinkUrl = '';
  optInSettings: OptinSettings = { isEnabled: false };
  redirectAfterReservation = false;
  redirectDelay = 0;
  redirectUrl = '';
  requireAcceptTerms = false;
  requireCvv = false;
  requirePhoneNumber = false;
  showMap = true;
  showRestrictionsOnCalendar = true;
  showUnavailableRooms = false;
  totalPriceForStay = false;

  constructor({ custom, settings, show_estimated_arrival_time }: RawProperty) {
    this.showMap = !parseRawBoolean(settings?.hideMap);
    this.showRestrictionsOnCalendar =
      settings?.showRestrictionsOnCalendar === undefined
        ? true
        : parseRawBoolean(settings?.showRestrictionsOnCalendar);
    this.askPostalAddress = parseRawBoolean(settings?.bookingPostalAddress);
    this.askNationality = parseRawBoolean(settings?.bookingNationality);
    this.askGender = parseRawBoolean(settings?.bookingGender);
    this.askGuestTaxID = parseRawBoolean(settings?.askGuestTaxIdNumber);
    this.askCompanyName = parseRawBoolean(settings?.askCompanyName);
    this.askCompanyTaxID = parseRawBoolean(settings?.askCompanyTaxIdNumber);
    this.requirePhoneNumber = parseRawBoolean(
      settings?.bookingRequireTelephone
    );
    this.askEstimatedArrivalTime =
      parseRawBoolean(show_estimated_arrival_time) &&
      settings?.bookingEstimatedArrivalTime !== '0';

    this.bookingEstimatedArrivalTime =
      !!settings?.bookingEstimatedArrivalTime &&
      settings?.bookingEstimatedArrivalTime !== '0'
        ? settings.bookingEstimatedArrivalTime
        : {};
    this.bookingEstimatedArrivalTime.notAllowedHours = Array.isArray(
      this.bookingEstimatedArrivalTime.notAllowedHours
    )
      ? this.bookingEstimatedArrivalTime.notAllowedHours
      : [];
    this.requireAcceptTerms = parseRawBoolean(
      settings?.bookingRequireTermsConditions
    );
    this.redirectAfterReservation = !!settings?.bookingRedirectUrl;
    this.logoLinkUrl = custom?.logoLinkUrl || '';
    this.redirectDelay =
      !!settings?.bookingRedirectUrl && settings.bookingRedirectUrl !== '0'
        ? parseInt(settings?.bookingRedirectUrl?.time)
        : 0;
    this.redirectUrl =
      !!settings?.bookingRedirectUrl && settings?.bookingRedirectUrl !== '0'
        ? settings?.bookingRedirectUrl?.url
        : '';
    this.requireCvv = parseRawBoolean(settings?.bookingSettingsCvv);
    this.totalPriceForStay = parseRawBoolean(settings?.totalPriceForStay);
    this.allowedBookingLanguages = settings?.allowedBookingLanguages || [];
    this.showUnavailableRooms = parseRawBoolean(settings?.showUnavailableRooms);
    this.coverImageUrl = custom?.coverImageUrl || '';
    this.displayAddons = settings?.displayAddons || 'preCheckout';

    this.optInSettings = {
      isEnabled: parseRawBoolean(settings?.isOptInOutEnabledOnBooking),
    };

    if (this.optInSettings.isEnabled) {
      this.optInSettings.description = new MultilangText(
        settings?.optInSettings?.opt_in_description_text
      );
      this.optInSettings.optInText = new MultilangText(
        settings?.optInSettings?.opt_in_option_text
      );
      this.optInSettings.optOutText = new MultilangText(
        settings?.optInSettings?.opt_out_option_text
      );
    }

    if (custom?.colors) {
      const colorsSetByUser = custom.colors;

      this.colors.primary = createColorScale(
        colorsSetByUser.primary || ThemeColors.primary[500]
      );

      this.colors.primaryContrast = createContrastColorScale(
        this.colors.primary[600]
      );

      this.colors.headerBackground = createColorScale(
        colorsSetByUser.header || ThemeColors.headerBackground[500]
      );

      this.colors.mainPageBackground = createColorScale(
        colorsSetByUser.mainPageBackground ||
          ThemeColors.mainPageBackground[500]
      );

      this.colors.background = createColorScale(colorsSetByUser.background);
    }
  }
}
