import { RawCartTotals } from '@data/api/types/cart-totals';

import { DetailedTaxesAndFees } from './DetailedTaxesAndFees';

export default class CartTotals {
  cart_token!: string;
  deposit!: number;
  extraAdultCharge!: number;
  extraChildCharge!: number;
  fees!: DetailedTaxesAndFees;
  grandTotal!: number;
  taxes!: DetailedTaxesAndFees;
  taxesAndFees!: DetailedTaxesAndFees;
  total!: number;

  constructor(serverData?: {
    data: RawCartTotals;
    statusCode: number;
    statusMessage: string | null;
    success: number;
  }) {
    if (!serverData || !serverData.success || !serverData.data) {
      return;
    }

    const {
      data: {
        addAdultsPrice,
        addKidsPrice,
        deposit,
        fees,
        grandTotal,
        taxes,
        total,
        cart_token,
      },
    } = serverData;

    this.cart_token = cart_token;

    this.extraAdultCharge = addAdultsPrice || 0;
    this.extraChildCharge = addKidsPrice || 0;
    this.total = total;
    this.deposit = deposit;
    this.grandTotal = grandTotal;

    this.taxes = {
      data: taxes.data.map(({ credit, id, name }) => ({
        credit,
        id: id || '',
        name: name || '',
      })),
      total: taxes.total,
    };

    this.fees = {
      data: fees.data.map(({ credit, id, name }) => ({
        credit,
        id: id || '',
        name: name || '',
      })),
      total: fees.total,
    };

    this.taxesAndFees = {
      data: [...this.taxes.data, ...this.fees.data],
      total: this.taxes.total + this.fees.total,
    };
  }
}
