import { action, makeObservable, observable } from 'mobx';

import { Guest, Guest as GuestModel } from '@data/models';

import { IPersistentStore } from '../IPersistentStore';
import { persistentPropertyStorage } from '../PersistentPropertyStorage';

export default class Guests implements IPersistentStore {
  @observable additional: GuestModel[] = [];
  @observable main: Guest = new Guest();

  constructor() {
    makeObservable(this);
  }

  get storageKey(): string {
    return 'guests';
  }

  @action.bound add(guest: GuestModel) {
    return this.additional.push(guest);
  }

  @action.bound clear = () => {
    this.main = new Guest();
    this.additional = [];
  };

  load() {
    const loadedData = persistentPropertyStorage.getItem(this.storageKey);
    if (loadedData) {
      const parsedData = JSON.parse(loadedData);
      this.main = parsedData.main;
      this.additional = parsedData.additional;
    }
  }

  @action.bound remove(index: number) {
    if (this.additional.length > index) {
      this.additional.splice(index, 1);
    }
  }

  save() {
    persistentPropertyStorage.setItem(this.storageKey, JSON.stringify(this));
  }

  @action.bound update(index: number, guest: GuestModel) {
    if (index >= 0 && this.additional.length > index) {
      this.additional[index] = guest;
    }
  }

  @action.bound updateMainGuest(guest: GuestModel) {
    this.main = guest;
  }

  @action.bound updateMarketingOptin(value: boolean) {
    this.main = { ...this.main, isOptIn: value };
  }
}
