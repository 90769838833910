import { Property } from '@data/models';
import { Language } from '@data/types/common';

import { apiCall, IRequestOptions } from '../axios';

export type GetPropertyRequest = {
  code?: Property['code'];
  id?: Property['id'];
  language?: Language;
  options?: IRequestOptions;
};

export class PropertyService {
  static getProperty({
    code,
    id,
    language = 'en',
    options = {},
  }: GetPropertyRequest) {
    const url = '/property_info';
    return apiCall(Property)(
      'post',
      url,
      { lang: language, property_code: code, property_id: id },
      options
    );
  }
}
