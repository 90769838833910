import axios from 'axios';
import type { Class } from 'type-fest';

import { getApiBaseURL } from '../../config';

import { onApiError } from './errors';

const axiosInstance = axios.create();

export interface IRequestOptions {
  baseURL?: string;
  headers?: Dict;
  responseType?: string;
}

type MethodType = 'get' | 'delete' | 'put' | 'post' | 'patch';
export interface IRequestConfig {
  data?: Dict | string;
  headers?: Dict;
  method?: MethodType;
  params?: Dict | string;
  url?: string;
}

function toFormData(data?: Dict | string) {
  if (!data) return '';
  if (typeof data === 'string') return data;
  const formDataArray: string[] = [];

  const appendFormData = (key: string, value: any) => {
    if (Array.isArray(value)) {
      value.forEach((item: any) => {
        appendFormData(`${key}[]`, item); // recursively handle nested arrays
      });
    } else if (typeof value === 'object') {
      Object.keys(value).forEach((subKey) => {
        appendFormData(`${key}[${subKey}]`, value[subKey]); // recursively handle nested objects
      });
    } else {
      formDataArray.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value)
      );
    }
  };

  Object.keys(data).forEach((key) => {
    appendFormData(key, data[key]);
  });

  return formDataArray.join('&');
}

async function axiosCall(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (axiosInstance.defaults.baseURL === undefined) {
    axiosInstance.defaults.baseURL = getApiBaseURL();
  }
  return axiosInstance
    .request(configs)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
}

function getConfigs(
  method: MethodType,
  contentType: string,
  url: string,
  options: Dict
): IRequestConfig {
  const headers = {
    ...options.headers,
    'content-type': contentType,
  };
  return {
    ...options,
    headers,
    method,
    url,
  };
}

type Valuable<T> = {
  [K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

function getValuable<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends {},
  V = Valuable<T>
>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === 'string' && !v.length) ||
          v === null ||
          typeof v === 'undefined'
        )
    )
  ) as V;
}

export const apiCall =
  <T>(Model: Class<T>) =>
  async (
    method: MethodType,
    url: string,
    params?: Dict,
    options: IRequestOptions = {}
  ) => {
    return new Promise<T>((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        method,
        'application/x-www-form-urlencoded',
        url,
        options
      );

      const data = params ? getValuable(params) : undefined;
      if (method === 'get' || method === 'delete') {
        configs.params = data;
      } else {
        configs.data = toFormData({ ...data });
      }

      axiosCall(
        configs,
        (response: Dict) => {
          const obj: T = new Model(response);
          resolve(obj);
        },
        reject
      );
    }).catch((err) => {
      throw onApiError(url, params, err);
    });
  };
