import { makeObservable, observable } from 'mobx';

/**
 * As of now, custom payment option is only configurable by custom JS.
 * We support only one and when this is set in the environment,
 * the data shown in the bank transfer option is replaced with this data.
 *
 * It is mandatory that the property has bank transfer enabled in order to use a custom payment option.
 */
export default class CustomPaymentOption {
  @observable id: string;
  @observable instructions?: string;
  @observable name: string;

  constructor({ id, instructions, name }: CustomPaymentOption) {
    makeObservable(this);

    this.id = id;
    this.instructions = instructions;
    this.name = name;
  }
}
