import ColorUtils from 'color';

export type HexColor = `#${string}`;
export type RgbColor = { r: number; g: number; b: number };

export type ColorShade =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export type ColorName =
  | 'white'
  | 'black'
  | 'background'
  | 'primary'
  | 'primaryContrast'
  | 'success'
  | 'error'
  | 'warning'
  | 'neutral'
  | 'headerBackground'
  | 'mainPageBackground';

export type ColorScale = {
  50: HexColor;
  100: HexColor;
  200: HexColor;
  300: HexColor;
  400: HexColor;
  500: HexColor;
  600: HexColor;
  700: HexColor;
  800: HexColor;
  900: HexColor;
};

export type ThemeColorsType = {
  background: ColorScale;
  black: ColorScale;
  error: ColorScale;
  headerBackground: ColorScale;
  mainPageBackground: ColorScale;
  neutral: ColorScale;
  primary: ColorScale;
  primaryContrast: ColorScale;
  success: ColorScale;
  warning: ColorScale;
  white: ColorScale;
};

function hexToRgb(hex: HexColor): RgbColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        b: parseInt(result[3], 16),
        g: parseInt(result[2], 16),
        r: parseInt(result[1], 16),
      }
    : { b: 0, g: 0, r: 0 };
}

function brightnessAdjust(color: HexColor, amt: number): HexColor {
  return ColorUtils(hexToRgb(color)).lighten(amt).hex();
}

export function createColorScale(color: HexColor): ColorScale {
  const multipliers = {
    100: 0.8,
    200: 0.6,
    300: 0.4,
    400: 0.2,
    50: 0.9,
    500: 0,
    600: -0.25,
    700: -0.5,
    800: -0.7,
    900: -0.9,
  };
  const scale = {};
  for (const key in multipliers) {
    scale[key] = brightnessAdjust(color, multipliers[key]);
  }
  return scale as ColorScale;
}

export function createContrastColorScale(baseColor: HexColor): ColorScale {
  const scale = createColorScale(baseColor);
  for (const key in scale) {
    scale[key] = ColorUtils(scale[key]).isDark() ? 'white' : 'black';
  }
  return scale as ColorScale;
}

// Do not import ThemeColors directly into components.
// Use the ThemeColors off of Settings instead, as those values will be
// updated when the user changes their colors in MFD settings.

export const ThemeColors: ThemeColorsType = {
  background: createColorScale('#F6F5F5'),
  black: createColorScale('#000000'),
  error: {
    100: '#FEEEEF',
    200: '#FCD5D7',
    300: '#F9ABAE',
    400: '#F68086',
    50: '#FEF7F7',
    500: '#F3565D',
    600: '#B64149',
    700: '#792D35',
    800: '#3D1617',
    900: '#180C16',
  },
  headerBackground: createColorScale('#FFFFFF'),
  mainPageBackground: createColorScale('#F8F9FA'),
  neutral: {
    100: '#F1F3F4',
    200: '#E4E6EA',
    300: '#DDE0E4',
    400: '#BBC0CA',
    50: '#F8F9FA',
    500: '#99A1AF',
    600: '#778295',
    700: '#596273',
    800: '#3B4351',
    900: '#1E2330',
  },
  primary: createColorScale('#32C0A0'),
  primaryContrast: createContrastColorScale('#32C0A0'),
  success: createColorScale('#32C0A0'),
  warning: createColorScale('#FBC02D'),
  white: createColorScale('#FFFFFF'),
};

export type Darkness =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

type Colors =
  | 'white'
  | 'black'
  | 'primary'
  | 'success'
  | 'error'
  | 'warning'
  | 'neutral';

export default Colors;
