import { EventEmitter } from 'events';

import {
  EventEmitOptions,
  EventListenerFunction,
  EventType,
  IEventSystem,
  IExternalEventSystem,
  PayloadOf,
} from './types';

class EventSystem implements IEventSystem {
  private eventEmitter: EventEmitter;
  private externalEventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.externalEventEmitter = new EventEmitter();
  }

  emit = <E extends EventType>(
    event: E,
    payload: PayloadOf<E>,
    options: EventEmitOptions = { visibility: 'internal' }
  ) => {
    this.eventEmitter.emit(event, payload);
    if (options.visibility === 'external') {
      this.externalEventEmitter.emit(event, payload);
    }
  };

  off = <E extends EventType>(event: E, listener: EventListenerFunction<E>) => {
    this.eventEmitter.off(event, listener);
  };

  on = <E extends EventType>(event: E, listener: EventListenerFunction<E>) => {
    this.eventEmitter.on(event, listener);
  };
}

class ExternalEventSystem implements IExternalEventSystem {
  constructor(private eventEmitter: EventEmitter) {}

  addEventListener = <E extends EventType>(
    event: E,
    listener: EventListenerFunction<E>
  ) => {
    this.eventEmitter.on(event, listener);
  };

  dispatchEvent = <E extends EventType>(event: E, payload: PayloadOf<E>) => {
    this.eventEmitter.emit(event, payload);
  };

  removeEventListener = <E extends EventType>(
    event: E,
    listener: EventListenerFunction<E>
  ) => {
    this.eventEmitter.off(event, listener);
  };
}

export const eventSystem = new EventSystem();
export const externalEventSystem = new ExternalEventSystem(
  eventSystem['externalEventEmitter']
);
