import { Language } from '@data/types/common';

export type RawMultiLangText = Partial<Record<Language, string>>;

class MultilangText {
  cn?: string;
  cs?: string;
  de?: string;
  el?: string;
  en!: string;
  es?: string;
  et?: string;
  fi?: string;
  fr?: string;
  he?: string;
  hu?: string;
  it?: string;
  ja?: string;
  ko?: string;
  lt?: string;
  nl?: string;
  no?: string;
  pl?: string;
  'pt-br'?: string;
  ro?: string;
  ru?: string;
  sk?: string;
  sv?: string;
  th?: string;
  ua?: string;
  vn?: string;

  constructor(data?: any) {
    if (data?.constructor === String) {
      this.en = data as string;
      return;
    }
    Object.assign(this, data);
  }

  getText = (lang = 'en'): string => this[lang] || this.en;

  has = (lang: Language): boolean => !!this[lang];
}

export default MultilangText;
