import { QueryClient } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

const useQueryCache = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: useQueryCache
        ? 1000 * 60 * 60 * 24 /* 24 hours */
        : 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
      suspense: true,
      useErrorBoundary: true /* 5 minutes */,
    },
  },
});

export async function setupQueryCache() {
  if (!useQueryCache) return;

  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  await persistQueryClient({
    buster: '2',
    persistor: localStoragePersistor,
    queryClient,
  });
}

export default queryClient;
