import {
  initialize as LDInitialize,
  LDClient,
  LDFlagSet,
  basicLogger as LDBasicLogger,
  LDContext,
} from 'launchdarkly-js-client-sdk';

import { Property } from '@data/models';

import { FeatureFlag } from './FeatureFlag';

type FeatureFlagsInitializeOptions = {
  apiKey: string;
  property?: Property;
};

type FeatureFlagsChangeContextOptions = {
  property?: Property;
};

type FeatureFlagEvent = 'change';
type FeatureFlagEventCallback = () => void;

class FeatureFlagManager {
  private client: LDClient | undefined;
  private data: LDFlagSet = {};

  private events: Record<string, FeatureFlagEventCallback[]> = {};

  async changeContext({
    property,
  }: FeatureFlagsChangeContextOptions): Promise<void> {
    if (!this.client) return;
    await this.client.identify(this.getLaunchDarklyContext(property));
  }

  private dispatchEvent(event: FeatureFlagEvent) {
    this.events[event]?.forEach((c) => c?.());
  }

  private getLaunchDarklyContext(property?: Property): LDContext {
    const name = property?.name || 'Anonymous Context';
    const key = `property:${property?.id || 0}`;

    return {
      key,
      kind: 'user',
      name,
    };
  }

  initialize({
    apiKey,
    property,
  }: FeatureFlagsInitializeOptions): Promise<void> {
    return new Promise((res) => {
      this.client?.close();

      this.client = LDInitialize(
        apiKey,
        this.getLaunchDarklyContext(property),
        {
          logger: LDBasicLogger({ level: 'none' }),
        }
      );
      this.client.on('initialized', () => this.onReceiveFlagData());
      this.client.on('change', () => this.onReceiveFlagData());
      this.client.on('ready', () => {
        res();
      });
    });
  }

  isEnabled(flag: FeatureFlag): boolean {
    return this.data[flag] || false;
  }

  off(event: FeatureFlagEvent, callback: FeatureFlagEventCallback) {
    if (this.events[event] === undefined) return;
    const index = this.events[event].indexOf(callback);
    this.events[event].splice(index, 1);
  }

  on(event: FeatureFlagEvent, callback: () => void) {
    if (this.events[event] === undefined) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  private onReceiveFlagData() {
    this.data = this.client?.allFlags() || {};
  }
}

const instance = new FeatureFlagManager();

// @TECHDEBT: In order to test, we can use this global function
// that is a temporary solution and should be replaced
// by a different solution later (like a chrome extension for instance)
// Ideally all the test functions should be centralized in a unique parent global object
window['overrideFeatureFlag'] = (key: string, value: boolean) => {
  instance['data'][key] = value;
  instance['dispatchEvent']('change');
};

export default instance;
