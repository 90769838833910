import { makeObservable, observable, runInAction } from 'mobx';

import { Addon, AddonList as AddonListModel } from '@data/models';
import { AddonsService, AddonsSearchParams } from '@data/api/services/addons';
import MultilangText from '@data/models/MultilangText';

import { IPersistentStore } from '../IPersistentStore';
import { persistentPropertyStorage } from '../PersistentPropertyStorage';

export default class Addons implements IPersistentStore {
  @observable isFetching = false;

  @observable model: AddonListModel | null = null;

  constructor() {
    makeObservable(this);
  }

  get storageKey(): string {
    return 'addons';
  }

  load() {
    const loadedData = persistentPropertyStorage.getItem(this.storageKey);
    if (loadedData) {
      const parsedData = JSON.parse(loadedData);

      const model = new AddonListModel();
      parsedData?.model?.items.forEach((item: Addon) => {
        const addon = new Addon();
        Object.assign(addon, item);
        addon.name = new MultilangText(addon.name);
        addon.description = new MultilangText(addon.description);
        model.items.push(addon);
      });

      this.model = model;
    }
  }

  save() {
    persistentPropertyStorage.setItem(this.storageKey, JSON.stringify(this));
  }

  search = async ({
    accommodations,
    checkin,
    checkout,
    language,
    propertyId,
  }: AddonsSearchParams) => {
    runInAction(() => {
      this.model = null;
    });

    runInAction(() => {
      this.isFetching = true;
    });

    const result = !(propertyId && checkin && checkout)
      ? null
      : await AddonsService.search({
          accommodations,
          checkin,
          checkout,
          language,
          propertyId,
        });

    runInAction(() => {
      this.isFetching = false;
      this.model = result;
    });

    return result;
  };
}
