import Store from '@data/stores/Store';

import { translateCartItem } from './translators';
import { eventTypeToGaEventType, TRACKING_EVENTS_TYPE } from './consts';

export type TrackingEvent = {
  data?: object;
  type: TRACKING_EVENTS_TYPE;
};

// @TECHDEBT: Make this track be triggered by the event system
const trackModifyCart = (event) => {
  const itemWrapper = event.data?.item;
  if (!Store.property.model || !itemWrapper) {
    return;
  }

  const modifyCartEvent = {
    currency: Store.environment.currency.toUpperCase(),
    items: [translateCartItem(itemWrapper)],
    property_id: Store.property.model.id.toString(),
    value: itemWrapper?.item?.rate * itemWrapper.amount,
  };

  const eventType = eventTypeToGaEventType[event.type];

  window.gtag?.('event', eventType, modifyCartEvent);
};

export const trackEvent = async (event: TrackingEvent): Promise<void> => {
  return new Promise((resolve) => {
    const { type } = event;

    switch (type) {
      case TRACKING_EVENTS_TYPE.ADD_TO_CART:
      case TRACKING_EVENTS_TYPE.REMOVE_FROM_CART:
        trackModifyCart(event);
        return resolve();
      default:
        console.log(`Tracking event ${type} not implemented`);
        // @TECHDEBT: maybe we should reject in this case, unit test needs to be updated
        return resolve();
    }
  });
};
