import { RawAccommodation } from '../types/accommodation';
import {
  BooleanNumber,
  BooleanString,
  DateString,
  YesNoBoolean,
} from '../types/native';

export const parseRawBoolean = (
  value?: BooleanString | YesNoBoolean | BooleanNumber
): boolean => value === '1' || value === 'Y' || value === 1;

const convertToDateString = (
  year: string,
  month: string,
  day: string
): DateString =>
  `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}` as DateString;

/**
 * Helper function to parse the key of the API response for /booking/addons endpoint which has the following format:
 *
 * roomId-packageId-checkinYear-checkinMonth-checkinDay-checkoutYear-checkoutMonth-checkoutDay-adults-kids
 *
 */
export const parseRawAddonListKey = (key: string) => {
  const [
    roomId,
    packageId,
    checkinYear,
    checkinMonth,
    checkinDay,
    checkoutYear,
    checkoutMonth,
    checkoutDay,
    adults,
    kids,
  ] = key.split('-');

  return {
    adults: parseInt(adults),
    checkin: convertToDateString(checkinYear, checkinMonth, checkinDay),
    checkout: convertToDateString(checkoutYear, checkoutMonth, checkoutDay),
    kids: parseInt(kids),
    packageId: parseInt(packageId),
    roomId,
  };
};

export const getRawAccommodationLinkedId = ({
  package_id,
  rate_id,
  room_type_id,
}: Pick<RawAccommodation, 'package_id' | 'rate_id' | 'room_type_id'>) =>
  `${room_type_id}-${package_id}-${rate_id}`;
