import generateUUID from '@business/utils/uuid';

import Accommodation from './Accommodation';
import Addon from './Addon';

export type ReservationItemAccommodation = {
  adults: number;
  amount: number;
  associatedSplitInventoryUnits: string[];
  item: Accommodation;
  kids: number;
  reservationId?: string;
  type: 'accommodation';
  unit?: string;
};

export type ReservationItemAddons = {
  accommodationUUID?: ReservationItem['id'];
  amount: number;
  item: Addon;
  reservationId?: string;
  type: 'addon';
  unit?: string;
};

export type ReservationItemModel =
  | ReservationItemAccommodation
  | ReservationItemAddons;

export default class ReservationItem {
  id!: string;
  model?: ReservationItemModel;

  constructor(data: ReservationItemModel, uuid?: string) {
    this.id = uuid || generateUUID();
    this.model = data;
  }

  getItemId() {
    return this.model?.item.id;
  }

  getLinkingId() {
    let packageId = '';
    let roomId = '';
    let adults = 1;
    let kids = 0;
    let unit: string | undefined = undefined;
    if (this.model?.type === 'accommodation') {
      packageId = this.model.item.packageId;
      roomId = this.model.item.id;
      adults = this.model.adults;
      kids = this.model.kids;
      unit = this.model.unit;
    } else if (this.model?.type === 'addon') {
      packageId = this.model.item.packageId;
      roomId = this.model.item.roomId;
      adults = this.model.item.adults;
      kids = this.model.item.kids;
      unit = this.model.unit;
    }
    return `${roomId}-${packageId}-${adults}-${kids}${unit ? `-${unit}` : ''}`;
  }

  getName() {
    if (!this.model) return '';
    if (this.model.type === 'accommodation') {
      if (this.model.unit) {
        return `${this.model.item.name} - ${this.model.unit}`;
      }
    }
    return this.model?.type === 'accommodation'
      ? this.model?.item.name
      : this.model?.item.name.getText();
  }

  get isAlreadyPurchased() {
    return (this.model?.item?.bookedId || []).length > 0;
  }
}
