export const currencies = [
  {
    text: 'Utd. Arab Emir. Dirham',
    value: 'aed',
  },
  {
    text: 'Afghanistan Afghani',
    value: 'afn',
  },
  {
    text: 'Albanian Lek',
    value: 'all',
  },
  {
    text: 'Armenian Dram',
    value: 'amd',
  },
  {
    text: 'NL Antillian Guilder',
    value: 'ang',
  },
  {
    text: 'Angolan Kwanza',
    value: 'aoa',
  },
  {
    text: 'Argentine Peso',
    value: 'ars',
  },
  {
    text: 'Australian Dollar',
    value: 'aud',
  },
  {
    text: 'Aruban Florin',
    value: 'awg',
  },
  {
    text: 'Azerbaijan New Manat',
    value: 'azn',
  },
  {
    text: 'Bosnian Mark',
    value: 'bam',
  },
  {
    text: 'Barbados Dollar',
    value: 'bbd',
  },
  {
    text: 'Bangladeshi Taka',
    value: 'bdt',
  },
  {
    text: 'Bulgarian Lev',
    value: 'bgn',
  },
  {
    text: 'Bahraini Dinar',
    value: 'bhd',
  },
  {
    text: 'Burundi Franc',
    value: 'bif',
  },
  {
    text: 'Bermudian Dollar',
    value: 'bmd',
  },
  {
    text: 'Brunei Dollar',
    value: 'bnd',
  },
  {
    text: 'Bolivian Boliviano',
    value: 'bob',
  },
  {
    text: 'Brazilian Real',
    value: 'brl',
  },
  {
    text: 'Bahamian Dollar',
    value: 'bsd',
  },
  /* This currency is not working on the API
  {
    text: 'Bitcoin',
    value: 'btc',
  },
  */
  {
    text: 'Bhutan Ngultrum',
    value: 'btn',
  },
  {
    text: 'Botswana Pula',
    value: 'bwp',
  },
  {
    text: 'Belarusian ruble',
    value: 'byn',
  },
  {
    text: 'Belize Dollar',
    value: 'bzd',
  },
  {
    text: 'Canadian Dollar',
    value: 'cad',
  },
  {
    text: 'Congolese Franc',
    value: 'cdf',
  },
  {
    text: 'Swiss Franc',
    value: 'chf',
  },
  {
    text: 'Chilean Unidad de Fomento',
    value: 'clf',
  },
  {
    text: 'Chilean Peso',
    value: 'clp',
  },
  {
    text: 'Chinese Yuan Renminbi',
    value: 'cny',
  },
  {
    text: 'Colombian Peso',
    value: 'cop',
  },
  {
    text: 'Costa Rican Colon',
    value: 'crc',
  },
  {
    text: 'Cuban Convertible Peso',
    value: 'cuc',
  },
  {
    text: 'Cuban Peso',
    value: 'cup',
  },
  {
    text: 'Cape Verde Escudo',
    value: 'cve',
  },
  {
    text: 'Czech Koruna',
    value: 'czk',
  },
  {
    text: 'Djibouti Franc',
    value: 'djf',
  },
  {
    text: 'Danish Krone',
    value: 'dkk',
  },
  {
    text: 'Dominican R. Peso',
    value: 'dop',
  },
  {
    text: 'Algerian Dinar',
    value: 'dzd',
  },
  {
    text: 'Egyptian Pound',
    value: 'egp',
  },
  {
    text: 'Eritrean Nakfa',
    value: 'ern',
  },
  {
    text: 'Ethiopian Birr',
    value: 'etb',
  },
  {
    text: 'Euro',
    value: 'eur',
  },
  {
    text: 'Fiji Dollar',
    value: 'fjd',
  },
  {
    text: 'Falkland Islands Pound',
    value: 'fkp',
  },
  {
    text: 'British Pound',
    value: 'gbp',
  },
  {
    text: 'Georgian Lari',
    value: 'gel',
  },
  /* This currency is not working on the API
  {
    text: 'Guernsey Pound',
    value: 'ggp',
  },
  */
  {
    text: 'Ghanaian New Cedi',
    value: 'ghs',
  },
  {
    text: 'Gibraltar Pound',
    value: 'gip',
  },
  {
    text: 'Gambian Dalasi',
    value: 'gmd',
  },
  {
    text: 'Guinea Franc',
    value: 'gnf',
  },
  {
    text: 'Guatemalan Quetzal',
    value: 'gtq',
  },
  {
    text: 'Guyanese Dollar',
    value: 'gyd',
  },
  {
    text: 'Hong Kong Dollar',
    value: 'hkd',
  },
  {
    text: 'Honduran Lempira',
    value: 'hnl',
  },
  {
    text: 'Croatian Kuna',
    value: 'hrk',
  },
  {
    text: 'Haitian Gourde',
    value: 'htg',
  },
  {
    text: 'Hungarian Forint',
    value: 'huf',
  },
  {
    text: 'Indonesian Rupiah',
    value: 'idr',
  },
  {
    text: 'Israeli New Shekel',
    value: 'ils',
  },
  /* This currency is not working on the API
  {
    text: 'Isle of Man Pound',
    value: 'imp',
  },
  */
  {
    text: 'Indian Rupee',
    value: 'inr',
  },
  {
    text: 'Iraqi Dinar',
    value: 'iqd',
  },
  {
    text: 'Iranian Rial',
    value: 'irr',
  },
  {
    text: 'Iceland Krona',
    value: 'isk',
  },
  /* This currency is not working on the API
  {
    text: 'Jersey Pound',
    value: 'jep',
  },
  */
  {
    text: 'Jamaican Dollar',
    value: 'jmd',
  },
  {
    text: 'Jordanian Dinar',
    value: 'jod',
  },
  {
    text: 'Japanese Yen',
    value: 'jpy',
  },
  {
    text: 'Kenyan Shilling',
    value: 'kes',
  },
  {
    text: 'Kyrgyzstanian Som',
    value: 'kgs',
  },
  {
    text: 'Cambodian Riel',
    value: 'khr',
  },
  {
    text: 'Comoros Franc',
    value: 'kmf',
  },
  {
    text: 'North Korean Won',
    value: 'kpw',
  },
  {
    text: 'South Korean Won',
    value: 'krw',
  },
  {
    text: 'Kuwaiti Dinar',
    value: 'kwd',
  },
  {
    text: 'Cayman Islands Dollar',
    value: 'kyd',
  },
  {
    text: 'Kazakhstan Tenge',
    value: 'kzt',
  },
  {
    text: 'Lao Kip',
    value: 'lak',
  },
  {
    text: 'Lebanese Pound',
    value: 'lbp',
  },
  {
    text: 'Sri Lanka Rupee',
    value: 'lkr',
  },
  {
    text: 'Liberian Dollar',
    value: 'lrd',
  },
  {
    text: 'Lesotho Loti',
    value: 'lsl',
  },
  {
    text: 'Libyan Dinar',
    value: 'lyd',
  },
  {
    text: 'Moroccan Dirham',
    value: 'mad',
  },
  {
    text: 'Moldovan Leu',
    value: 'mdl',
  },
  {
    text: 'Malagasy Ariary',
    value: 'mga',
  },
  {
    text: 'Macedonian Denar',
    value: 'mkd',
  },
  {
    text: 'Myanmar Kyat',
    value: 'mmk',
  },
  {
    text: 'Mongolian Tugrik',
    value: 'mnt',
  },
  {
    text: 'Macau Pataca',
    value: 'mop',
  },
  {
    text: 'Mauritanian ouguiya',
    value: 'mru',
  },
  {
    text: 'Mauritius Rupee',
    value: 'mur',
  },
  {
    text: 'Maldive Rufiyaa',
    value: 'mvr',
  },
  {
    text: 'Malawi Kwacha',
    value: 'mwk',
  },
  {
    text: 'Mexican Peso',
    value: 'mxn',
  },
  {
    text: 'Malaysian Ringgit',
    value: 'myr',
  },
  {
    text: 'Mozambique New Metical',
    value: 'mzn',
  },
  {
    text: 'Namibia Dollar',
    value: 'nad',
  },
  {
    text: 'Nigerian Naira',
    value: 'ngn',
  },
  {
    text: 'Nicaraguan Cordoba Oro',
    value: 'nio',
  },
  {
    text: 'Norwegian Kroner',
    value: 'nok',
  },
  {
    text: 'Nepalese Rupee',
    value: 'npr',
  },
  {
    text: 'New Zealand Dollar',
    value: 'nzd',
  },
  {
    text: 'Omani Rial',
    value: 'omr',
  },
  {
    text: 'Panamanian Balboa',
    value: 'pab',
  },
  {
    text: 'Peruvian Nuevo Sol',
    value: 'pen',
  },
  {
    text: 'Papua New Guinea Kina',
    value: 'pgk',
  },
  {
    text: 'Philippine Peso',
    value: 'php',
  },
  {
    text: 'Pakistan Rupee',
    value: 'pkr',
  },
  {
    text: 'Polish Zloty',
    value: 'pln',
  },
  {
    text: 'Paraguay Guarani',
    value: 'pyg',
  },
  {
    text: 'Qatari Rial',
    value: 'qar',
  },
  {
    text: 'Romanian New Lei',
    value: 'ron',
  },
  {
    text: 'Serbian Dinar',
    value: 'rsd',
  },
  {
    text: 'Russian Rouble',
    value: 'rub',
  },
  {
    text: 'Rwandan Franc',
    value: 'rwf',
  },
  {
    text: 'Saudi Riyal',
    value: 'sar',
  },
  {
    text: 'Solomon Islands Dollar',
    value: 'sbd',
  },
  {
    text: 'Seychelles Rupee',
    value: 'scr',
  },
  {
    text: 'Sudanese Pound',
    value: 'sdg',
  },
  {
    text: 'Swedish Krona',
    value: 'sek',
  },
  {
    text: 'Singapore Dollar',
    value: 'sgd',
  },
  {
    text: 'St. Helena Pound',
    value: 'shp',
  },
  {
    text: 'Sierra Leone Leone',
    value: 'sll',
  },
  {
    text: 'Somali Shilling',
    value: 'sos',
  },
  {
    text: 'Suriname Dollar',
    value: 'srd',
  },
  {
    text: 'São Tomé and Príncipe dobra',
    value: 'stn',
  },
  {
    text: 'El Salvador Colon',
    value: 'svc',
  },
  {
    text: 'Syrian Pound',
    value: 'syp',
  },
  {
    text: 'Swaziland Lilangeni',
    value: 'szl',
  },
  {
    text: 'Thai Baht',
    value: 'thb',
  },
  {
    text: 'Tajikistani Somoni',
    value: 'tjs',
  },
  {
    text: 'Turkmenistan New Manat',
    value: 'tmt',
  },
  {
    text: 'Tunisian Dinar',
    value: 'tnd',
  },
  {
    text: 'Tonga Pa’anga',
    value: 'top',
  },
  {
    text: 'Turkish New Lira',
    value: 'try',
  },
  {
    text: 'Trinidad/Tobago Dollar',
    value: 'ttd',
  },
  {
    text: 'Taiwan Dollar',
    value: 'twd',
  },
  {
    text: 'Tanzanian Shilling',
    value: 'tzs',
  },
  {
    text: 'Ukraine Hryvnia',
    value: 'uah',
  },
  {
    text: 'Uganda Shilling',
    value: 'ugx',
  },
  {
    text: 'US Dollar',
    value: 'usd',
  },
  {
    text: 'Uruguayan Peso',
    value: 'uyu',
  },
  {
    text: 'Uzbekistani Som',
    value: 'uzs',
  },
  {
    text: 'Venezuelan bolívar soberano',
    value: 'ves',
  },
  {
    text: 'Vietnamese Dong',
    value: 'vnd',
  },
  {
    text: 'Vanuatu Vatu',
    value: 'vuv',
  },
  {
    text: 'Samoan Tala',
    value: 'wst',
  },
  {
    text: 'CFA Franc BEAC',
    value: 'xaf',
  },
  {
    text: 'East Caribbean Dollar',
    value: 'xcd',
  },
  {
    text: 'CFA Franc BCEAO',
    value: 'xof',
  },
  {
    text: 'CFP Franc',
    value: 'xpf',
  },
  {
    text: 'Yemeni Rial',
    value: 'yer',
  },
  {
    text: 'South African Rand',
    value: 'zar',
  },
  {
    text: 'Zambian Kwacha',
    value: 'zmw',
  },
  {
    text: 'Zimbabwean dollar',
    value: 'zwl',
  },
] as const;
