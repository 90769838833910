import { ChargeType, Reservation } from '@data/models';
import { Currency } from '@data/types/common';

export enum TRACKING_EVENTS_TYPE {
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  AMPLIFY_PAGE_LOAD = 'amplify_page_load',
}

export const eventTypeToGaEventType = {
  [TRACKING_EVENTS_TYPE.ADD_TO_CART]: 'add_to_cart',
  [TRACKING_EVENTS_TYPE.REMOVE_FROM_CART]: 'remove_from_cart',
};

interface TrackedCartItem {
  amount: number;
  type: 'accommodation' | 'addon';
}

export interface TrackedAddon extends TrackedCartItem {
  item: TrackedAddonItem;
}

export interface TrackedAccommodation extends TrackedCartItem {
  adults: number;
  kids: number;
  item: TrackedAccommodationItem;
}

export interface PurchaseEventData {
  data: {
    property: {
      defaultCurrency: Currency;
      name: string;
    };
    reservation: Reservation;
  };
}

export type TrackedAddonItem = {
  chargeType: ChargeType;
  id: string;
  name: string;
  packageId: string;
  productId: string;
  quantity: number;
  rate: number;
  roomId: string;
};

export type TrackedAccommodationItem = {
  adults: string;
  beds: string;
  id: string;
  isPackage: boolean;
  isPrivate: boolean;
  kids: string;
  name: string;
  nights: number;
  packageId: string;
  packageName: string;
  rate: number;
  rateId: string;
};

export interface AmplifyPageLoadEventData {
  data: {
    googleTroas?: {
      conversionID: string;
      conversionLabel: string;
    };
    retargeting?: {
      advertisableID: string;
      pixelID: string;
    };
  };
}

type GA4Event = {
  event_callback?: () => void;
  send_to: string | string[];
};

export type GA4PurchaseEvent = GA4Event & {
  affiliation: string;
  coupon: string;
  currency: Uppercase<Currency>;
  items: {
    affiliation: string;
    item_id: string;
    item_name: string;
    price: number;
    quantity: number;
  }[];
  tax: number;
  transaction_id: string;
  value: number;
};
