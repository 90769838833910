import { useCallback, useEffect, useState } from 'react';

import { FeatureFlag } from './FeatureFlag';
import FeatureFlagManager from './FeatureFlagManager';

export const useFeatureFlag = (flag: FeatureFlag) => {
  const [val, setVal] = useState<boolean>(FeatureFlagManager.isEnabled(flag));

  const onFlagsChanged = useCallback(() => {
    setVal(FeatureFlagManager.isEnabled(flag));
  }, [flag]);

  useEffect(() => {
    FeatureFlagManager.on('change', onFlagsChanged);
    onFlagsChanged();
    return () => {
      FeatureFlagManager.off('change', onFlagsChanged);
    };
  }, [onFlagsChanged]);

  return val;
};
