import {
  RawAccommodationList,
  isApiAccommodationListError,
} from '@data/api/types/accommodation-list';
import { parseRawBoolean } from '@data/api/helpers';

import Accommodation from './Accommodation';
import UnavailableAccommodation from './UnavailableAccommodation';
import RateCheckerData from './RateCheckerData';

export default class AccommodationList {
  bookableLimitEnabled!: boolean;
  checkin!: string;
  checkout!: string;
  currency_rate!: number;
  items: Accommodation[] = [];
  rateCheckerData!: RateCheckerData;
  splitRoomRelations!: Record<string, string[]> | [];
  unavailableItems: UnavailableAccommodation[] = [];

  constructor(data?: RawAccommodationList) {
    if (!!data && isApiAccommodationListError(data)) {
      this.currency_rate = 1;
      this.rateCheckerData = new RateCheckerData();

      return;
    }

    this.currency_rate = data?.currency_rate || 1;

    this.splitRoomRelations = data?.roomsRelation || [];
    this.items = data?.room_types?.map((room) => new Accommodation(room)) || [];

    this.rateCheckerData = new RateCheckerData(data?.ma_rates);

    this.bookableLimitEnabled =
      parseRawBoolean(data?.bookable_limit_enabled) &&
      !!data?.booking_limit_per_person;

    this.unavailableItems =
      data?.unavailable_room_types?.map(
        (room) => new UnavailableAccommodation(room)
      ) || [];
  }
}
