import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ThemeColors } from '@theme/foundations/colors';
import { hexToRgba } from '@utils/view-layer/colors';
import { backgroundStyles, colorStyle } from '@utils/view-layer/styles';

import { BEIconOwnProps } from './BEIconProps';

const borderStyle = ({
  showBorder,
  skin,
}: Pick<BEIconOwnProps, 'showBorder' | 'skin'>) => {
  if (!showBorder) {
    return '';
  }

  let borderColor = '';
  switch (skin) {
    case 'primary':
      borderColor = hexToRgba(ThemeColors['neutral']['500'], 0.5);
      break;
    case 'error':
      borderColor = hexToRgba(ThemeColors['error']['100'], 0.5);
      break;
    case 'warning':
      borderColor = hexToRgba(ThemeColors['warning']['100'], 0.5);
      break;
    case 'success':
      borderColor = hexToRgba(ThemeColors['success']['100'], 0.5);
      break;
    default:
      borderColor = ThemeColors['neutral']['500'];
      break;
  }

  return css`
    border: 1px solid ${borderColor};

  `;
};

const sizeStyle = (props: Pick<BEIconOwnProps, 'feature' | 'size'>) => {
  // default is md
  let size = '3.6rem';
  let fontStyle = `
    font-size: 24;
    line-height: 24;
  `;

  if (props.feature) {
    switch (props.size) {
      case 'xs':
        size = '24px';
        fontStyle = `
          font-size: 10px;
          line-height: 18px;
        `;
        break;
      case 'sm':
        size = '24px';
        fontStyle = `
          font-size: 12px;
          line-height: 18px;
        `;
        break;
      case 'md':
        size = '24px';
        fontStyle = `
          font-size: 14px;
          line-height: 24px;
        `;
        break;
      case 'lg':
        size = '24px';
        fontStyle = `
          font-size: 18px;
          line-height: 24px;
        `;
        break;
      case 'xl':
        size = '5.6rem';
        fontStyle = `
          font-size: 28px;
          line-height: 28px;
        `;
        break;
      case '2xl':
        size = '6.4rem';
        fontStyle = `
          font-size: 36px;
          line-height: 36px;
        `;
        break;
      case '3xl':
        size = '10rem';
        fontStyle = `
          font-size: 59px;
          line-height: 59px;
        `;
        break;
      default:
        size = '4rem';
        fontStyle = `
          font-size: 20px;
          line-height: 20px;
        `;
        break;
    }
  } else {
    switch (props.size) {
      case 'xs':
        size = '24px';
        fontStyle = `
          font-size: 10px;
          line-height: 18px;
        `;
        break;
      case 'sm':
        size = '24px';
        fontStyle = `
          font-size: 12px;
          line-height: 18px;
        `;
        break;
      case 'md':
        size = '24px';
        fontStyle = `
          font-size: 14px;
          line-height: 24px;
        `;
        break;
      case 'lg':
        size = '24px';
        fontStyle = `
          font-size: 18px;
          line-height: 24px;
        `;
        break;
      case 'xl':
        size = '48px';
        fontStyle = `
          font-size: 24px;
          line-height: 24px;
        `;
        break;
      case '2xl':
        size = '4.8rem';
        fontStyle = `
          font-size: 36px;
          line-height: 36px;
        `;
        break;
      case '3xl':
        size = '10rem';
        fontStyle = `
          font-size: 59px;
          line-height: 59px;
        `;
        break;
      default:
        size = '3.6rem';
        fontStyle = `
          font-size: 24px;
          line-height: 24px;
        `;
        break;
    }
  }

  return css`
    height: ${size};
    ${fontStyle};
    width: ${size};
  `;
};

const positionStyle = ({ center }: Pick<BEIconOwnProps, 'center'>) => {
  if (center) {
    return css`
      align-items: center;
      display: inline-flex;
    `;
  }
};

const skinStyle = ({ skin }: Pick<BEIconOwnProps, 'skin'>) => {
  if (!skin) {
    return '';
  }

  if (skin === 'error') {
    return css`
      background-color: ${ThemeColors.error['100']};
      color: ${ThemeColors.error['600']};
    `;
  } else if (skin === 'warning') {
    return css`
      background-color: ${ThemeColors.warning['100']};
      color: ${ThemeColors.warning['600']};
    `;
  } else if (skin === 'success') {
    return css`
      background-color: ${ThemeColors.success['100']};
      color: ${ThemeColors.warning['600']};
    `;
  }
};

const featureStyle = ({ feature }: Pick<BEIconOwnProps, 'feature'>) => {
  if (!feature) {
    return '';
  }

  return css`
    border-radius: 50%;
  `;
};

const StyledBEIcon = styled('span')<Omit<BEIconOwnProps, 'name'>>`
  justify-content: center;
  ${(props) =>
    props.background && backgroundStyles({ background: props.background })}
  ${(props) => props.color && colorStyle(props.color)}
  ${borderStyle}
  ${skinStyle}
  ${featureStyle}
  ${(props) => sizeStyle(props)}
  ${(props) => positionStyle(props)}
`;

export { StyledBEIcon };
