const fontSizes: Record<FontSize, `${number}px`> = {
  '2xl': '20px', // h2
  '3xl': '24px', // h1
  '4xl': '28px', // h1
  '5xl': '32px',
  '6xl': '48px',
  lg: '16px', // h3
  md: '14px',
  sm: '12px',
  xl: '18px', // h2
  xs: '10px',
};

type FontSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl';

export const getFontSize = (size: FontSize): number => {
  const matches = fontSizes[size].match(/^\d+/);

  if (!matches) {
    throw new Error('Invalid font size');
  }

  return Number(matches[0]) as number;
};

export type Weights = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export default fontSizes;
