import { RawAccommodationType } from '@data/api/types/accommodation-type';

import Image from './Image';

function extractFirstURL(str: string, extesion = '.jpg') {
  const regex = /https:\/\/[^\s]+/;
  const match = str.match(regex);

  if (match && match.length > 0) {
    const firstIndex = match[0].indexOf(extesion);

    if (firstIndex >= 0) {
      return match[0].substring(0, firstIndex + extesion.length);
    }
  }

  return null;
}

export class AccommodationType {
  id!: string;
  images: Image[] = [];
  name!: string;
  packageId?: string;

  constructor(data?: RawAccommodationType) {
    if (!data) {
      return;
    }

    this.id = data.room_type_id;
    this.name = data.title;
    this.packageId = data.package_id || '0';

    const pictureURL = extractFirstURL(data.room_type_photos);

    if (pictureURL) {
      this.images.push(new Image(pictureURL));
    }
  }
}
