import { action, computed, makeObservable, observable } from 'mobx';

import type { Payment as PaymentType } from '@data/types/Payment';
import { PAYMENT_METHOD, PaymentSubmitData } from '@data/types/Payment';

export default class Payment {
  @observable model: PaymentType = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound clear = () => {
    this.model = {};
  };

  @action.bound setCreditCard = (card: PaymentType['card']) => {
    this.model.method = 'card';
    this.model.card = card;
  };

  @action.bound setMethod = (method: PAYMENT_METHOD) => {
    this.model = { ...this.model, method };
  };

  @action.bound setSubmitData = (data: PaymentSubmitData) => {
    this.model.billingDetails = data.billingDetails;
    this.model.hash = data.paymentMethodHash;
    this.model.id = data.paymentMethodId;
    this.model.method = data.paymentMethod;
    this.model.token = data.token;
  };

  @computed get creditCard() {
    return this.model.card;
  }

  @computed get paymentMethod() {
    return this.model.method;
  }
}
