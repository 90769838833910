import { system } from '../system';

system.load({
  amplifyMetasearchConnectorServiceApiBase:
    process.env.REACT_APP_AMPLIFY_METASEARCH_CONNECTOR_SERVICE_API_BASE,
  apiBase: process.env.REACT_APP_API_BASE,
  appEnv: process.env.REACT_APP_ENVIRONMENT as (typeof system)['appEnv'],
  env: process.env.NODE_ENV,
  experience: 'hosted',
  island: process.env.REACT_APP_ISLAND,
  launchDarklyApiKey: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY,
  marketingInsightesServiceApiBase:
    process.env.REACT_APP_MARKETING_INSIGHTS_SERVICE_API_BASE,
  staticFiles: process.env.REACT_APP_STATIC_FILES,
});
