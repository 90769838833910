import { computed, makeObservable, observable, runInAction } from 'mobx';

import { AmplifyService } from '@data/api';
import { AmplifyFeature } from '@data/api/types/amplify/features';
import { Property } from '@data/models';
import { ScriptData } from '@data/models/amplify';

import Store from '../Store';

export default class Amplify {
  @observable scriptData: ScriptData | undefined;

  constructor() {
    this.scriptData = new ScriptData();
    makeObservable(this);
  }

  getScriptData = async (propertyId?: Property['id']) => {
    if (!propertyId) return;

    // Marketing Insights Service
    const misTypes: AmplifyFeature[] = [];
    // Amplify Metasearch Connector Service
    const amcsTypes: AmplifyFeature[] = [];

    Store.property.model?.amplifyFeatures?.forEach((feature) => {
      switch (feature) {
        case AmplifyFeature.Retargeting:
          misTypes.push(AmplifyFeature.Retargeting);
          break;
        case AmplifyFeature.MetasearchAds:
          amcsTypes.push(AmplifyFeature.MetasearchAds);
          break;
        default:
          break;
      }
    });

    let result: ScriptData | undefined = undefined;

    if (misTypes.length) {
      const misResult = await AmplifyService.getScriptDataFromMIS({
        propertyID: propertyId,
        types: misTypes,
      });
      result = {
        retargeting: misResult.retargeting,
      };
    }

    if (amcsTypes.length) {
      const amcsResult = await AmplifyService.getScriptDataFromAMCS({
        propertyID: propertyId,
        types: amcsTypes,
      });
      result = {
        ...result,
        googleTroas: amcsResult.googleTroas,
      };
    }

    if (!result) return;

    runInAction(() => {
      this.scriptData = result;
    });

    return result;
  };

  @computed get isRetargetingEnabled() {
    return !!(
      this.scriptData?.retargeting?.advertisableID &&
      this.scriptData?.retargeting?.pixelID
    );
  }

  @computed get isGoogleTroasEnabled() {
    return !!(
      this.scriptData?.googleTroas?.conversionID &&
      this.scriptData?.googleTroas?.conversionLabel
    );
  }
}
