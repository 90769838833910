import { ApiAccommodation } from './accommodation';
import { ApiAccommodationType } from './accommodation-type';
import { ApiMaData } from './ma-data';
import {
  BooleanNumber,
  BooleanString,
  DD,
  MM,
  NumberString,
  YYYY,
} from './native';
import { ApiUnavailableAccommodation } from './unavailable-accommodation';

// Fields returned by API but not used in the constructor
interface ApiAccommodationListExtraFields {
  links: Record<
    NumberString,
    {
      level: string; // or number string?
      link_id: NumberString;
      roomId: NumberString;
      roomTypeId: NumberString;
      rooms: {
        [key: NumberString]: {
          cnt: number;
          roomIds: Record<string, string>;
        };
      };
    }[]
  >;
  total: number;
  website_source_id: NumberString;
}

// Fields returned by the server when allotment code block code is invalid
interface ApiAccommodationListInvalidAllotmentBlockCode {
  allotment_block_code_error: true;
  success: false;
}

// Fields returned by the server when promo code is out of range
interface ApiAccommodationListPromoOutOfRange {
  accomodation_types: Record<string, unknown>;
  promo_available: {
    booking_window: '' | unknown;
    closed_to_arrival: 'No' | 'Yes';
    cut_off: '0' | unknown;
    date_end: `${MM}/${DD}/${YYYY}`;
    date_start: `${MM}/${DD}/${YYYY}`;
    days: 'All' | 'Mon' | 'Sun - Mon, Wed - Sat' | 'Wed - Fri' | unknown; // Some examples of values retruned by the server
    days_full:
      | false
      | 'Monday'
      | 'Sunday - Monday, Wednesday - Saturday'
      | 'Wednesday - Friday'
      | unknown; // Some examples of values retruned by the server
    is_avail_for_booking: boolean;
    is_days_all: boolean;
    last_minute_booking: BooleanString;
    los_max: NumberString;
    los_min: NumberString;
  }[];
  success: false;
}

type ApiAccommodationListError =
  | ApiAccommodationListInvalidAllotmentBlockCode
  | ApiAccommodationListPromoOutOfRange;

// All fields returned by the API, move extra fields here to be able to use them in the constructor
export interface ApiAccommodationList
  extends Partial<ApiAccommodationListExtraFields> {
  accomodation_types?: ApiAccommodationType[];
  bookable_limit_enabled: BooleanNumber;
  booking_limit_per_person: boolean;
  currency_rate: number;
  ma_rates: boolean | ApiMaData[];
  room_types: ApiAccommodation[];
  roomsRelation: Record<string, string[]> | [];
  unavailable_room_types: ApiUnavailableAccommodation[];
}

// Fields returned by the API and used in the constructor
export type RawAccommodationList =
  | Omit<ApiAccommodationList, keyof ApiAccommodationListExtraFields>
  | ApiAccommodationListError;

const isApiAccommodationListPromoOutOfRange = (
  value: RawAccommodationList
): value is ApiAccommodationListPromoOutOfRange =>
  Object.hasOwn(value, 'promo_available');

const isApiAccommodationListInvalidAllotmentBlockCode = (
  value: RawAccommodationList
): value is ApiAccommodationListInvalidAllotmentBlockCode =>
  Object.hasOwn(value, 'allotment_block_code_error');

export const isApiAccommodationListError = (
  value: RawAccommodationList
): value is ApiAccommodationListError =>
  isApiAccommodationListPromoOutOfRange(value) ||
  isApiAccommodationListInvalidAllotmentBlockCode(value);
