import { makeObservable, observable, runInAction } from 'mobx';

import { ReservationService } from '@data/api';
import { Reservation as ReservationModel } from '@data/models';
import { MakeReservationRequest } from '@data/api/services/reservation';
import { eventSystem } from '@business/event-system';
import { PAYMENT_METHOD } from '@data/types/Payment';

const storage = window.sessionStorage;

export default class Reservation {
  @observable isFetching = false;
  @observable isProcessing = false;

  @observable model: ReservationModel | null = null;
  @observable reservationCode: string | null = null;

  constructor() {
    makeObservable(this);
  }

  clearCreatedReservationId() {
    storage.removeItem('createdReservationId');
  }

  async get(reservationCode: string): Promise<ReservationModel | null> {
    runInAction(() => {
      this.isFetching = true;
    });

    const result = await ReservationService.getReservation(reservationCode);

    runInAction(() => {
      this.reservationCode = decodeURIComponent(reservationCode);
      this.model = result;
      this.isFetching = false;
    });

    return this.model;
  }

  isReservationJustCreated() {
    const storedValue = storage.getItem('createdReservationId');
    return this.model && storedValue === this.model.reservationId;
  }

  async make(req: MakeReservationRequest) {
    this.clearCreatedReservationId();

    runInAction(() => {
      this.isProcessing = true;
      this.model = null;
      this.reservationCode = null;
    });

    try {
      const makeResult = await ReservationService.makeReservation(req);

      if (!makeResult.status) {
        throw new Error();
      }

      eventSystem.emit('internal/checkout-initiated', {
        paymentMethod: req.payment.method as PAYMENT_METHOD,
      });

      storage.setItem('createdReservationId', makeResult.reservation_id);

      runInAction(() => {
        this.reservationCode = makeResult.enc_res_id;
        this.isProcessing = false;
      });

      return makeResult;
    } catch (e) {
      runInAction(() => {
        this.isProcessing = false;
      });

      throw new Error('payment processing error');
    }
  }
}
