const hexExp = /^#[A-Za-z0-9]{3,4}$|^#[A-Za-z0-9]{6,8}$/;
const rgbExp = /^rgba?\(\s?([0-9]*)\s?,\s?([0-9]*)\s?,\s?([0-9]*)\s?\)/;
const rgbaExp =
  /^rgba?\(\s?([0-9]*)\s?,\s?([0-9]*)\s?,\s?([0-9]*)\s?,\s?([.0-9]*)\s?\)/;
const canExtractRGBArray = (color: string) =>
  hexExp.test(color) || rgbExp.test(color) || rgbaExp.test(color);

const parseHexToRGB = (color: string): Array<number> => {
  const defaultReturn = [0, 0, 0];

  if (!color) {
    return defaultReturn;
  }

  return color.length < 7
    ? color.match(/[A-Za-z0-9]{1}/g)?.map((v) => parseInt(`${v}${v}`, 16)) ||
        defaultReturn
    : // https://stackoverflow.com/a/42429333
      color.match(/[A-Za-z0-9]{2}/g)?.map((v) => parseInt(v, 16)) ||
        defaultReturn;
};

const getRGBArray = (color: string): Array<number> => {
  if (hexExp.test(color)) {
    const [red, green, blue, alpha] = parseHexToRGB(color);
    return [red, green, blue, alpha !== 1 ? alpha / 255.0 : 1];
  }
  let match = color.match(rgbExp);
  if (match) {
    return match.splice(1).map((v) => parseInt(v));
  }

  match = color.match(rgbaExp);
  if (match) {
    return match.splice(1).map((v) => parseFloat(v));
  }

  return [];
};

export const colorIsDark = (color: string) => {
  if (color && canExtractRGBArray(color)) {
    const [red, green, blue, alpha] = getRGBArray(color);
    if (alpha && alpha < 0.5) return undefined;
    const brightness = (299 * red + 587 * green + 114 * blue) / 1000;
    return brightness < 125;
  }
  return undefined;
};

export const hexToRgba = (hex: string, alpha: number) => {
  if (typeof hex === 'string') {
    const [r, g, b] = getRGBArray(hex);
    return `rgba(${r},${g},${b},${alpha})`;
  }

  throw new Error('Bad Hex');
};
