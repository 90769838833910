import React, { useCallback, useState } from 'react';

import { Flex, Image, Input } from '@chakra-ui/react';
import Text from '@molecules/Text';
import Button from '@molecules/Button';
import Card from '@molecules/Card';
import BEIcon from '@molecules/BEIcon';

import { ENVIRONMENT } from '../../config';

const Main: React.FC = () => {
  const [url, setURL] = useState<string>('');

  // On this part of the code we want to redirect using location.href
  // to force a page reload, initializing with the new URL.
  const onClickOpenProperty = useCallback(() => {
    const loc = new URL(url);
    window.location.href = loc.pathname;
  }, [url]);

  return (
    <Flex justifyContent="center" p="20px" w="100%">
      <Flex
        alignItems="center"
        flexDir="column"
        gap="20px"
        maxW="640px"
        textAlign="center"
        w="100%"
      >
        <Image alt="logo" src="./cb-logo.svg" />
        <Card>
          <Text fontWeight={600} mb={2} size="xl">
            BookingEngine {ENVIRONMENT}
          </Text>
          <Flex gap="16px">
            <Input
              _placeholder={{ opacity: 0.6 }}
              bg="white"
              onChange={(e) => setURL(e.target.value)}
              placeholder="Booking Engine url..."
              value={url}
            ></Input>
            <Button onClick={onClickOpenProperty}>Open</Button>
          </Flex>
          <Flex alignItems="center" gap="8px" mt="8px">
            <BEIcon color="neutral" name="info-circle" />
            <Text color="neutral" size="sm" textAlign="left">
              Enter the url of the Cloudbeds's booking engine you want to try on
              the new v3 system.
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default Main;
