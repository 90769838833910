import { arrayIncludes } from './array';

export const isObject = (o: unknown): o is object =>
  typeof o === 'object' && o !== null;

export const sortObjectByKeys = <T extends object>(unordered: T): T =>
  Object.keys(unordered)
    .sort()
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: unordered[key],
      }),
      {} as T
    );

export const objectIncludes = (o: object, p: object): boolean =>
  Object.keys(p).every((key) => {
    if (p[key] === undefined) {
      return Object.hasOwn(o, key) && o[key] === undefined;
    }

    if (isObject(p[key])) {
      return isObject(o[key]) ? objectIncludes(o[key], p[key]) : false;
    }

    if (Array.isArray(p[key])) {
      return Array.isArray(o[key]) ? arrayIncludes(o[key], p[key]) : false;
    }

    return o[key] === p[key];
  });

export const getSize = (o: object) => Object.keys(o).length;

type NonUndefined<T> = T extends undefined ? never : T;
type NonNull<T> = T extends null ? never : T;

type CleanType<T extends object> = {
  [P in keyof T]: null extends T[P] ? NonNull<T[P]> : NonUndefined<T[P]>;
};

export const removeNullishValues = <T extends object>(o: T): CleanType<T> =>
  Object.keys(o).reduce((acc, key) => {
    if (o[key] !== null && o[key] !== undefined) {
      return { ...acc, [key]: o[key] };
    }

    return acc;
  }, {} as CleanType<T>);
