import { Property } from '@data/models';
import { ScriptData } from '@data/models/amplify';

import { apiCall, IRequestOptions } from '../axios';
import { AmplifyFeature } from '../types/amplify/features';
import {
  AMPLIFY_METASEARCH_CONNECTOR_SERVICE_API,
  MARKETING_INSIGHTS_SERVICE_API,
} from '../../../config';

interface GetScriptDataParams {
  propertyID?: Property['id'];
  types: AmplifyFeature[];
}

export class AmplifyService {
  private static getScriptData(
    host: string,
    params: GetScriptDataParams,
    options: IRequestOptions = {}
  ) {
    return new Promise<ScriptData>((resolve, reject) => {
      const url = `${host}/scripts/getScriptData`;
      apiCall(ScriptData)('post', url, params, options)
        .then((res) => {
          resolve(res);
        })
        .catch(reject);
    });
  }
  static getScriptDataFromAMCS(
    params: GetScriptDataParams,
    options: IRequestOptions = {}
  ) {
    return AmplifyService.getScriptData(
      AMPLIFY_METASEARCH_CONNECTOR_SERVICE_API,
      params,
      options
    );
  }
  static getScriptDataFromMIS(
    params: GetScriptDataParams,
    options: IRequestOptions = {}
  ) {
    return AmplifyService.getScriptData(
      MARKETING_INSIGHTS_SERVICE_API,
      params,
      options
    );
  }
}
