const EXPIRATION_MINUTES = 10;

class PersistentPropertyStorage {
  private propertyId = '';

  clear() {
    if (!this.propertyId) return;
    sessionStorage.removeItem(this.propertyId);
  }

  getItem(key: string): string | null {
    if (!this.propertyId) return null;
    const data = JSON.parse(sessionStorage.getItem(this.propertyId) || '{}');
    return data[key];
  }

  getSearchParams(): Record<string, string | undefined> {
    let str = this.getItem('_searchParams') || '';
    if (str.length > 0 && str[0] === '?') {
      str = str.slice(1);
    }
    const searchParams = new URLSearchParams(str);
    const res: Record<string, string | undefined> = {};
    searchParams.forEach((value, key) => {
      res[key] = value;
    });
    return res;
  }

  private getStoredData(): Record<string, string> {
    if (!this.propertyId) return {};
    const data = JSON.parse(sessionStorage.getItem(this.propertyId) || '{}');

    // Verify if the data has expired
    const now = new Date().getTime();
    if (data._expiration && parseInt(data._expiration) < now) {
      sessionStorage.removeItem(this.propertyId);
      return {};
    }

    return data;
  }

  init(propertyId: string | number) {
    this.propertyId = propertyId.toString();
  }

  get isInitialized() {
    return !!this.propertyId;
  }

  setItem(key: string, value: string) {
    const data = { ...this.getStoredData(), [key]: value };

    // update expiration
    data._expiration = (
      new Date().getTime() +
      1000 * 60 * EXPIRATION_MINUTES
    ).toString();

    sessionStorage.setItem(this.propertyId, JSON.stringify(data));
  }

  setSearchParams(searchParams: string) {
    this.setItem('_searchParams', searchParams);
  }
}

export const persistentPropertyStorage = new PersistentPropertyStorage();
