import React, { forwardRef, Suspense } from 'react';

import { Box } from '@chakra-ui/react';

import { BEIconProps } from './BEIconProps';
import { StyledBEIcon } from './StyledBEIcon';

const FontAwesomeWrapper = React.lazy(
  () => import(/* webpackChunkName: "fontawesome" */ './FontAwesomeWrapper')
);

// forwardRef is needed for when we need the BEIcon to trigger a tooltip
const BEIcon = forwardRef<HTMLSpanElement, BEIconProps>(
  ({ center = true, name, color, id, size = 'md', testid, ...rest }, ref) => (
    <Suspense>
      <Box color={color} data-testid={testid}>
        <StyledBEIcon
          {...rest}
          center={center}
          // Currently only used by the CustomPaymentOption component, keeping this format to be consistent with the other elements in the compoennt.
          data-id={id}
          ref={ref}
          size={size}
        >
          <FontAwesomeWrapper name={name} />
        </StyledBEIcon>
      </Box>
    </Suspense>
  )
);

export default BEIcon;
